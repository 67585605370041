import React from 'react';
import {alpha, makeStyles} from '@material-ui/core/styles';
import MatText from '@material-ui/core/TextField';
// import propTypes from 'prop-types';

const useStylesReddit = makeStyles((theme) => ({
  root: {
    border: '1px solid #e2e2e1',
    overflow: 'hidden',
    borderRadius: 4,
    backgroundColor: '#fcfcfb',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:hover': {
      backgroundColor: '#fff',
    },
    '&$focused': {
      backgroundColor: '#fff',
      boxShadow: `${alpha(theme.palette.primary.main, 0.2)} 0 0 0 2px`,
      borderColor: theme.palette.primary.main,
    },
    '& .Mui-disabled': {
      backgroundColor: '#fff',
      boxShadow: `${alpha(theme.palette.primary.main, 0.2)} 0 0 0 2px`,
      borderColor: theme.palette.primary.main,
      cursor: 'not-allowed',
    },
  },
  focused: {},
}));

Text.propTypes = {};

function Text({InputProps, ...props}) {
  const classesInput = useStylesReddit();

  return (
    <MatText
      fullWidth
      variant="filled"
      InputProps={{classes: classesInput, disableUnderline: true, ...InputProps}}
      {...props}
    />
  );
}

export default Text;
