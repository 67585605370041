import addresses from '../reducers/addresses';
import api from '../../services/api';

export function getAddresses() {
  return async (dispatch) => {
    const data = await api.addresses.findAll();
    dispatch(addresses.Creators.setAddresses(data));
    const others = await api.integrationsTerceros.findAll();
    dispatch(addresses.Creators.setOthersAddresses(others));
  };
}

export function getAddressCitiesIntegrationTerceros() {
  return async (dispatch) => {
    const data = await api.integrationsTerceros.find({
      query: {$client: {distinct_cities: 'true'}},
    });
    return data;
  };
}

export function setMainAddress(id) {
  return async (dispatch) => {
    const address = await api.addresses.patch(id, {main: 'true'});
    dispatch(getAddresses());
    return address;
  };
}

export function removeAddress(id) {
  return async (dispatch) => {
    const address = await api.addresses.remove(id);
    dispatch(addresses.Creators.delAddresses([address]));
    return address;
  };
}
