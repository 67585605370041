/* eslint-disable react-hooks/exhaustive-deps */
import React, {Fragment, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
// import {matchPath} from 'react-router';
import {useRouteMatch/* , useLocation */} from 'react-router-dom';
import {
  retryLoginAndGetCurrentUser,
  setHasPendingSecurity,
  setHasPendingUserData,
  setLoginDialogOpen,
} from '../redux/actions';
import {getShoppingCart} from '../redux/actions';
import {getUnits} from '../redux/actions';
// import {setResetSearchFilters} from '../redux/actions';

function Guard({children}) {
  // const location = useLocation();
  const matchAccount = useRouteMatch('/mi-cuenta');
  const matchCheckout = useRouteMatch('/checkout');

  const isAuth = useSelector((state) => state.auth.isAuthenticate);
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();

  // useEffect(() => {
  //   if (!matchPath(location.pathname, {path: ['/b', '/c-:id-name']})) {
  //     dispatch(setResetSearchFilters());
  //   }
  // }, [location.pathname]);

  useEffect(() => {
    dispatch(retryLoginAndGetCurrentUser());
  }, []);

  useEffect(() => {
    if (user.status === 'active' && user.company_id) {
      dispatch(getShoppingCart());
      dispatch(getUnits());
    }
  }, [isAuth, user.status, user.company_id]);

  useEffect(() => {
    if (isAuth) {
      dispatch(setLoginDialogOpen(false));
      if (user.status === 'pending security verification') {
        dispatch(setHasPendingSecurity(true));
        dispatch(setLoginDialogOpen(true));
      } else if (user.status === 'pending user data') {
        dispatch(setHasPendingUserData(true));
        dispatch(setLoginDialogOpen(true));
      }
    } else {
      if (matchAccount) {
        dispatch(setLoginDialogOpen(true));
      }
    }

  }, [
    isAuth,
    user.status,
    matchAccount && matchAccount.path,
    matchCheckout && matchCheckout.path,
  ]);

  return <Fragment>{children}</Fragment>;
}

export default Guard;
