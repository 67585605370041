import {createActions, createReducer} from 'reduxsauce';
import {addList, delList, reset, setList} from '../utilities';

const INITIAL_STATE = {
  designs: {},
};

const {Types, Creators} = createActions({
  setDesigns: ['designs'],
  addDesigns: ['designs'],
  delDesigns: ['designs'],
  setResetDesigns: null,
  setReset: null,
});

// noinspection JSUnresolvedVariable
export const designs = createReducer(INITIAL_STATE, {
  [Types.SET_DESIGNS]: setList('designs', 'code'),
  [Types.ADD_DESIGNS]: addList('designs', 'code'),
  [Types.DEL_DESIGNS]: delList('designs', 'code'),
  [Types.SET_RESET_DESIGNS]: reset(INITIAL_STATE),
  [Types.SET_RESET]: reset(INITIAL_STATE),
});

export default {
  Types,
  Creators,
};
