import React, {useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import MatCheckbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormLabel from '@material-ui/core/FormLabel';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  root: {
    flexDirection: 'row',
  },
  contentCheckbox: ({columns}) => {
    return {
      display: 'grid',
      gridTemplateColumns: `repeat(${columns}, 1fr)`,
    };
  },
}));

Checkbox.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  columns: PropTypes.number,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      label: PropTypes.string.isRequired,
    }).isRequired,
  ),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onChange: PropTypes.func,
};

function Checkbox({columns, label, name, options, value, onChange}) {
  const [state, setState] = React.useState({});

  const classes = useStyles({columns: columns || 1});

  const handleChange = (event) => {
    const newState = {...state, [event.target.name]: event.target.checked};
    setState(newState);
    onChange(newState);
  };

  useEffect(() => {
    setState(value || {});
  }, [value]);

  return (
    <FormControl component="fieldset" className={classes.root}>
      {label && <FormLabel component="legend">{label}</FormLabel>}
      <FormGroup aria-label={name} className={classes.contentCheckbox} name={name}>
        {options.map((it, i) => (
          <FormControlLabel
            key={i}
            label={it.label}
            control={<MatCheckbox checked={state[it.value]} onChange={handleChange} name={it.value} />}
          />
        ))}
      </FormGroup>
    </FormControl>
  );
}

export default Checkbox;
