import feathers from '@feathersjs/client';
import {HOST_API, PROTOCOL_HTTP, STORAGE} from '../enviroment';

const client = feathers();

// Configure an AJAX library (see below) with that client
client.configure(
  feathers
    .rest(`${PROTOCOL_HTTP}://${HOST_API}`)
    .fetch(window.fetch.bind(window)),
);

// client.configure(socketio(socket));
client.configure(
  feathers.authentication({
    path: '/authentication',
    entity: 'user',
    service: 'users',
    cookie: 'feathers-jwt',
    storageKey: 'feathers-jwt',
    storage: STORAGE,
  }),
);

export default client;
