import {from, Observable} from 'rxjs';
import {map, reduce} from 'rxjs/operators';
import lodash from 'lodash';
import feathersClient from './feathers-connection';
import {TIMEOUT} from '../enviroment';

export default class Service {
  // static event = new Subject();

  // static TYPE_CREATED = 'CREATED';
  // static TYPE_UPDATED = 'UPDATED';
  // static TYPE_PATCHED = 'UPDATED';
  // static TYPE_REMOVED = 'DELETED';

  constructor(serviceName) {
    this.serviceName = serviceName.trim();
    this.service = feathersClient.service(this.serviceName);
    this.service.timeout = TIMEOUT;

    // const listeningHandler = (type) => (data, context) =>
    //   Service.event.next({
    //     type,
    //     serviceName,
    //     data,
    //     context,
    //   });
    //
    // if (typeof watch === 'boolean' && watch) {
    //   this.service.on('created', listeningHandler(Service.TYPE_CREATED));
    //   this.service.on('updated', listeningHandler(Service.TYPE_UPDATED));
    //   this.service.on('patched', listeningHandler(Service.TYPE_PATCHED));
    //   this.service.on('removed', listeningHandler(Service.TYPE_REMOVED));
    // } else if (typeof watch === 'object') {
    //   if (watch.created)
    //     this.service.on('created', listeningHandler(Service.TYPE_CREATED));
    //   if (watch.updated)
    //     this.service.on('updated', listeningHandler(Service.TYPE_UPDATED));
    //   if (watch.patched)
    //     this.service.on('patched', listeningHandler(Service.TYPE_PATCHED));
    //   if (watch.removed)
    //     this.service.on('removed', listeningHandler(Service.TYPE_REMOVED));
    // }
  }

  // static getEvent(serviceName, type) {
  //   return Service.event.pipe(
  //     filter((it) => it.serviceName === serviceName && it.type === type),
  //   );
  // }
  //
  // static getEventByService(serviceName) {
  //   return Service.event.pipe(filter((it) => it.serviceName === serviceName));
  // }
  //
  // static getEventByType(type) {
  //   return Service.event.pipe(filter((it) => it.type === type));
  // }

  raw(method, ...rest) {
    // noinspection JSCheckFunctionSignatures
    return from(this.service[method](...rest));
  }

  // getEvent(
  //   type = [
  //     Service.TYPE_CREATED,
  //     Service.TYPE_UPDATED,
  //     Service.TYPE_PATCHED,
  //     Service.TYPE_REMOVED,
  //   ],
  // ) {
  //   if (!Array.isArray(type)) {
  //     type = [type];
  //   }
  //   return Service.event.pipe(
  //     filter(
  //       (it) => type.includes(it.type) && it.serviceName === this.serviceName,
  //     ),
  //     map((it) => ({type: it.type, data: it.data})),
  //   );
  // }

  // noinspection JSUnusedGlobalSymbols
  get(id, query) {
    return this.raw('get', id, query).toPromise();
  }

  // noinspection JSUnusedGlobalSymbols
  find(query = {}) {
    const hooks = (data) => {
      let added = {};

      if (data.skip >= data.limit)
        added.back = () =>
          this.find(
            lodash.merge(query, {
              query: {
                $limit: data.limit,
                $skip: data.skip - data.limit,
              },
            }),
          );
      if (data.skip + data.limit < data.total)
        added.next = () =>
          this.find(
            lodash.merge(query, {
              query: {
                $limit: data.limit,
                $skip: data.skip + data.limit,
              },
            }),
          );
      return added;
    };

    return this.raw('find', query)
      .pipe(
        map((it) => {
          if (query.paginate === false) return it;
          else return {...it, ...hooks(it)};
        }),
      )
      .toPromise();
  }

  // noinspection JSUnusedGlobalSymbols
  findAll(query = {}) {
    delete query.paginate;

    return new Observable((subscriber) => {
      let loopRecursive = (find) =>
        find.then(({data, next}) => {
          subscriber.next(data);
          if (next) loopRecursive(next());
          else subscriber.complete();
        });

      loopRecursive(this.find(query));
    })
      .pipe(reduce((acc, value) => [...acc, ...value]))
      .toPromise();
  }

  // noinspection JSUnusedGlobalSymbols
  create(data, query) {
    return this.raw('create', data, query).toPromise();
  }

  // noinspection JSUnusedGlobalSymbols
  update(id, data, query) {
    return this.raw('update', id, data, query).toPromise();
  }

  // noinspection JSUnusedGlobalSymbols
  patch(id, data, query) {
    return this.raw('patch', id, data, query).toPromise();
  }

  // noinspection JSUnusedGlobalSymbols
  remove(id, query) {
    return this.raw('remove', id, query).toPromise();
  }
}
