import {applyMiddleware, combineReducers, compose, createStore} from 'redux';
import thunk from 'redux-thunk';

// reducers
import {addresses} from './reducers/addresses';
import auth from './reducers/auth';
import {banks} from './reducers/banks';
import {categories} from './reducers/categories';
import {configs} from './reducers/configs';
import {creditCards} from './reducers/creditCards';
import {designs} from './reducers/designs';
import {eWallet} from './reducers/eWallet';
import filters from './reducers/filters';
import {fulfillments} from './reducers/fulfillments';
import notifications from './reducers/notifications';
import {orders} from './reducers/orders';
import {products} from './reducers/products';
import {search} from './reducers/search';
import {shoppingCart} from './reducers/shoppingCart';
import {uiConfig} from './reducers/uiConfig';
import {companiesBudgets} from './reducers/companiesBudgets';
import {productPriceList} from './reducers/productPriceList';
import {discounts} from './reducers/discounts'
import {paymentReducer} from './reducers/methodpay'

// noinspection JSUnresolvedVariable
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

function store() {
  return createStore(
    combineReducers({
      addresses,
      auth,
      banks,
      categories,
      configs,
      creditCards,
      designs,
      eWallet,
      filters,
      fulfillments,
      notifications,
      orders,
      products,
      search,
      shoppingCart,
      uiConfig,
      companiesBudgets,
      productPriceList,
      discounts,
      paymentReducer,
    }),
    composeEnhancers(applyMiddleware(thunk)),
  );
}

export default store;
