export const SELECTOR_SHOPPING_CART_ID = (state) => {
  return state.shoppingCart.shoppingCartId;
};

export const SELECTOR_SHOPPING_CART_PRODUCTS = (state) => {
  return Object.values(state.shoppingCart.products);
};

export const SELECTOR_SHOPPING_CART_PRODUCTS_COUNT = (state) => {
  return Object.keys(state.shoppingCart.products).length;
};

export const SELECTOR_SHOPPING_CART_PRODUCTS_RELATED = (state) => {
  return Object.values(state.shoppingCart.relateProducts);
};
