export const listado = {
  Institucional: [
    'Firmas de abogados',
    'Agencias de publicidad',
    'Empresas de recursos humanos',
    'Organizaciones sin fines de lucro',
    'Empresas de gestión de eventos',
    'Colegios o universidades',
    'Call center',
    'Clinicas o Hospitales',
    'Otro',
  ],
  'Grandes Superficies': [
    'Grandes cadenas de supermercados',
    'Hipermercados',
    'Tiendas de conveniencia',
    'Tiendas de electrónicos y tecnología',
    'Tiendas de ropa y moda',
    'Supermercados con enfoque en productos gourmet',
    'Grandes almacenes de artículos para el hogar',
    'Cadenas de jugueterías y entretenimiento',
    'Otro',
  ],
  Industrial: [
    'Fabricantes de productos químicos',
    'Empresas de fabricación de maquinaria pesada',
    'Plantas de producción de alimentos',
    'Empresas textiles e industriales',
    'Fabricantes de productos metálicos',
    'Plantas de producción de energía renovable',
    'Fabrica de productos farmacéuticos',
    'Industria automotriz y ensambladoras',
    'Empresas de fabricación de productos electrónicos',
    'Fabricantes de productos plásticos',
    'Empresas de fabricación de productos médicos',
    'Plantas de producción de materiales de construcción',
    'Fabricación de productos químicos para la agricultura',
    'Industria de fabricación de productos de papel',
    'Otro',
  ],
  'Horeca (Hoteles, Restaurantes, Cafeterías)': [
    'Hoteles resorts',
    'Restaurantes de alta cocina',
    'Cafeterías y pastelerías',
    'Servicios de catering',
    'Posadas y Hostales',
    'Restaurantes de comida rápida',
    'Bares y discotecas',
    'Moteles',
    'Food trucks y carritos de comida',
    'Restaurantes',
    'Otro',
  ],
  'Casas de Aseo': [
    'Empresas de limpieza y mantenimiento',
    'Empresas de limpieza de espacios comerciales',
    'Servicios de limpieza para hospitales y clínicas',
    'Empresas de limpieza industrial',
    'Servicios de eliminación de residuos peligrosos',
    'Empresas de limpieza de ductos y conductos de aire',
    'Servicios de desinfección y esterilización',
    'Otro',
  ],
  Lavanderías: [
    'Servicios de lavandería y tintorería',
    'Lavanderías industriales para textiles',
    'Otro',
  ],
  'Compañías de fumigación y control de plagas': [
    'Subdistribuidor',
    'Empresas de distribución de productos de limpieza',
    'Otro',
  ],
}
