/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {Box, Divider, Typography, useMediaQuery} from '@material-ui/core';
import {Star as StarIcon, StarBorder as StarBorderIcon} from '@material-ui/icons';
import {makeStyles} from '@material-ui/core/styles';
import {SELECTOR_PRODUCT_REVIEWS} from '../redux/selectors/products';
import {useDispatch, useSelector} from 'react-redux';
import {getProductReviews} from '../redux/actions/products';
import lodash from 'lodash';

const useStyles = makeStyles((theme) => ({
  reviewsWrapper: {
    flexDirection: ({isMobil}) => (isMobil ? 'initial' : 'row-reverse'),
  },
  reviewButton: {
    color: '#000',
    outline: 'none',
    background: 'transparent',
    border: 'none',
    textDecoration: 'underline',
    display: 'block',
    marginLeft: 'auto',
    marginBottom: 10,
    '@media screen and (min-width: 768px)': {
      display: 'flex',
      marginBottom: 0,
    },
  },
  subtitle: {
    color: '#000',
    fontWeight: 700,
    fontSize: 16,
  },
  reviewStars: {
    color: "#ffd333",
    minWidth: 120,
  },
  reviewText: {
    color: "#8d98a1",
  },
  reviewBar: {
    marginLeft: ({isMobil}) => (isMobil ? 0 : 10),
    background: "#ffd333",
    width: ({perSize}) => `${(perSize * 100) / 110 < 5 ? 5 : (perSize * 100) / 110}px`,
    height: 15,
  },
  reviewDetailText: {
    color: "#8d98a1",
    minWidth: 65,
  },
  reviewProfile: {
    background: '#ece8da',
    borderRadius: '50%',
    width: 50,
    height: 50,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: theme.spacing(3),
  },
  profileText: {
    fontSize: 20,
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  reviewUser: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
  },
}));

const ProductReviewBar = ({perSize, nStars, nReviews}) => {
  const isMobil = useMediaQuery('(max-width:767px)');
  const classes = useStyles({perSize, isMobil});

  return (
    <Box display="flex" mt={1} className={classes.reviewStars} alignItems="center">
      <div>
        {[...Array(nStars).keys()].map((_, i) => (
          <StarIcon key={`star_icon_review_${i}`} />
        ))}
        {[...Array(5 - nStars).keys()].map((_, i) => (
          <StarBorderIcon key={`star_border_icon_${i}`} />
        ))}
      </div>
      <Box display="flex" ml={1} alignItems="center">
        <div className={classes.reviewBar}></div>
        <Box display="flex" className={classes.reviewDetailText} ml={2} justifyContent="space-between">
          <Typography variant="inherit">{perSize}%</Typography>
          <Typography variant="inherit">({nReviews || 0})</Typography>
        </Box>
      </Box>
    </Box>
  );
};

ProductReviewBar.propTypes = {
  perSize: PropTypes.number.isRequired,
  nStars: PropTypes.number.isRequired,
  nReviews: PropTypes.number.isRequired,
};

const ProductReview = ({review}) => {
  const isMobil = useMediaQuery('(max-width:767px)');
  const classes = useStyles();

  return (
    <Box mt={3}>
      <Divider />
      <Box display="flex" mt={2}>
        <div className={classes.reviewProfile}>
          <Typography variant="inherit" className={classes.profileText}>
            {review.user[0].first_name[0]}
          </Typography>
        </div>
        <Box>
          <Box display={isMobil ? 'block' : 'flex'} alignItems="center">
            <div className={classes.reviewStars}>
              {[...Array(review.score).keys()].map((_, i) => (
                <StarIcon key={`review_stars_score_${i}`} />
              ))}
            </div>
            <Typography variant="inherit" style={{color: '#8d98a1', marginLeft: isMobil ? 0 : 10, fontWeight: 'bold'}}>
              {moment(review.createdAt).locale('es').format('DD/MM/YYYY')}
            </Typography>
          </Box>
          <Typography variant="h5" className={classes.reviewUser}>
            {review.user[0].first_name} {review.user[0].last_name}
          </Typography>
        </Box>
      </Box>
      <Box style={{marginLeft: 74}}>
        <Typography variant="body1" style={{marginTop: 10, fontSize: 14}}>
          {review.message}
        </Typography>
      </Box>
    </Box>
  );
};

ProductReview.propTypes = {
  review: PropTypes.object.isRequired,
};

const ProductReviews = ({product}) => {
  const [grouped, setGrouped] = useState();
  const reviews = useSelector(SELECTOR_PRODUCT_REVIEWS);

  const dispatch = useDispatch();

  const isMobil = useMediaQuery('(max-width:767px)');
  const classes = useStyles({isMobil});

  function _getReviews() {
    product?.id && dispatch(getProductReviews({product_id: product.id, $limit: 100}));
  }

  useEffect(() => {
    _getReviews();
  }, []);

  useEffect(() => {
    if (reviews.length) {
      const group = lodash.groupBy(reviews, 'score');
      setGrouped(group);
    }
  }, [reviews]);
  useEffect(() => {
      const group = lodash.groupBy(reviews, 'score');
      setGrouped(group);
  }, []);
  
  return (
    <Box my={isMobil ? 0 : 2} mx={isMobil ? 0 : 1}>
      <Box
        pb={2}
        mr={isMobil ? 0 : 5}
        className={classes.reviewsWrapper}
      >

        <Box>
          <Typography variant="h5" className={classes.subtitle}>
            CALIFICACIONES DE CLIENTES
          </Typography>
          <Box mt={2} display={isMobil ? 'block' : 'flex'}>
      
            <Box ml={isMobil ? 0 : 2}>
              {grouped && (
                <>
                  <ProductReviewBar perSize={(((grouped[5] || []).length * 100) / reviews.length) || 0} nStars={5} nReviews={(grouped[5] || []).length} />
                  <ProductReviewBar perSize={(((grouped[4] || []).length * 100) / reviews.length) || 0} nStars={4} nReviews={(grouped[4] || []).length} />
                  <ProductReviewBar perSize={(((grouped[3] || []).length * 100) / reviews.length) || 0} nStars={3} nReviews={(grouped[3] || []).length} />
                  <ProductReviewBar perSize={(((grouped[2] || []).length * 100) / reviews.length) || 0} nStars={2} nReviews={(grouped[2] || []).length} />
                  <ProductReviewBar perSize={(((grouped[1] || []).length * 100) / reviews.length) || 0} nStars={1} nReviews={(grouped[1] || []).length} />
                </>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
      {(reviews && !!reviews.length) && reviews.slice(0, 3).map((review, i) => <ProductReview review={review} key={`product_review_${i}`} />)}
    </Box>
  );
};

ProductReviews.propTypes = {
  product: PropTypes.object.isRequired,
};

export default ProductReviews;
