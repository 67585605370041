import searchReducer from '../reducers/search';

export function setSearchQuery(q) {
  return (dispatch) => {
    dispatch(searchReducer.Creators.setSearchQuery(q));
  };
}

export function setSearchPage(page) {
  return (dispatch) => {
    dispatch(searchReducer.Creators.setSearchPage(page));
  };
}

export function setSearchFilters(filters) {
  return (dispatch) => {
    dispatch(searchReducer.Creators.setSearchFilters(filters));
  };
}

export function setResetSearchFilters() {
  return (dispatch) => {
    dispatch(searchReducer.Creators.setResetSearch());
  };
}
