/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import {Badge, Box, Tab, Tabs} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import {useDispatch, useSelector} from 'react-redux';
import AppButton from './AppButton';
import AppDialog from './AppDialog';
import numeral from 'numeral';

// noinspection ES6CheckImport
import {getFullPicture} from '../modules/imageHandler';

import {addToShoppingCart} from '../redux/actions';
import CurrencyFormat from 'react-currency-format';
import api from '../services/api';

import ImageGallery from 'react-image-gallery';

import NumericInput from 'react-numeric-input';
import ItemProduct from './makeComponents/components/ItemProduct';
import {getProductById} from '../redux/actions';
import {SELECTOR_PRODUCT_SELECTED} from '../redux/selectors/products';
import {getProductData} from '../util/product';
import {useLocation} from 'react-router';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {SELECTOR_CONFIGS_UNITS} from '../redux/selectors/configs';
import {useGA4React} from 'ga-4-react';
import Helmet from './Helmet';
import ReactPixel from 'react-facebook-pixel';
import ProductReviews from '../views/ProductReviews';
import ProductSpecifications from '../views/ProductSpecifications';

require('react-image-gallery/styles/css/image-gallery.css');

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: '1.4rem',
    fontWeight: 700,
    textTransform: 'capitalize',
    lineHeight: 1.3,
    marginBottom: theme.spacing(1),
  },
  subtitle: {
    fontSize: '.8rem',
    fontWeight: 400,
    marginBottom: theme.spacing(2),
    color: theme.palette.text.hint,
  },
  price: {
    fontWeight: 700,
    fontSize: '1.8rem',
  },
  quantity: {
    fontWeight: 500,
    fontSize: '1.1rem',
    marginBottom: theme.spacing(2),
  },
  shipments: {
    fontWeight: 400,
    fontSize: '0.9rem',
    marginBottom: theme.spacing(0.2),
  },
  shipmentsBold: {
    fontWeight: 'bold',
    fontSize: '0.9rem',
    marginBottom: theme.spacing(0.2),
  },
  helpText: {
    fontWeight: 700,
    fontSize: '.9rem',
    color: '#5F5F5F',
    marginBottom: theme.spacing(1),
  },
  text: {
    fontSize: '.95rem',
    whiteSpace: 'pre-line',
  },
  image: {
    margin: '0 auto',
    display: 'block',
    height: 270,
  },
  close: {
    position: 'absolute',
    top: 20,
    right: 30,
    [theme.breakpoints.down('sm')]: {
      top: 10,
      right: 20,
    },
  },
  actions: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  addedProductButton: {
    marginLeft: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      marginTop: theme.spacing(2),
    },
  },
  imageGalleryWrapper: {
    position: 'relative',
    '& .image-gallery-image': {
      height: '270px!important',
    },
    '& .image-gallery-thumbnail-image': {
      width: 'auto!important',
      maxHeight: '50px!important',
    },
  },
  opacityBackgroundWrapper: {
    position: 'absolute',
    top: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 1)',
    opacity: '0.7',
    paddingRight: '15px',
  },
  tagSale: {
    fontSize: 11,
    color: 'white',
    position: 'absolute',
    padding: '2px 5px',
    top: 10,
    left: -5,
    background: theme.palette.error.main,
    borderRadius: 4,
    zIndex: 100,
  },
  rootTabs: {
    // maxWidth: 930,
  },
  tabSelected: {
    background: 'white',
    transition: '.14s all',
    border: `1px solid ${theme.palette.grey.light}`,
    fontWeight: 400,
    '&.Mui-selected': {
      background: theme.palette.primary.main,
      color: 'white',
      borderColor: theme.palette.primary.main,
    },
    '&:not(:last-child)': {
      borderRight: 0,
    },
  },
}));

const objTestProduct = {
  id: 886,
  name: 'COLCHON MISTRAL YORK 120X190CM - YORK',
  category_id: 7,
  category_id_2: null,
  category_id_3: null,
  brand_id: 33,
  discount_price: null,
  discount_price_whit_tax: null,
  price: 957899,
  price_list: 1652900,
  price_with_tax: 1139900,
  unit_measure: null,
  quantity_per_unit_measure: null,
  description: null,
  status: 'active',
  category_path_ids: '-1-7-',
  tax_rule_id: 3,
  quantity: 10,
  code: null,
  images: 'true',
  integration_id: '7704604797634',
  integration_hash: '7704604797634-COLCHON MISTRAL YORK 120X190CM - YORK-957899-10-0-0-0-0-0-1',
  slug: 'p-886-colchon-mistral-york-120x190cm-york',
  weight: 0,
  heigh: null,
  width: 0,
  long: 0,
  position_more_sales: null,
  review_score: null,
  review_quantity: null,
  is_ead: 1,
  label_id: null,
  label_name: null,
  label_position: null,
  label_start_date: null,
  label_end_date: null,
  label_path: null,
  enable_sync: 'true',
  deletedAt: null,
  createdAt: '2022-03-10T13:00:08.000Z',
  updatedAt: '2022-08-01T12:08:32.000Z',
  price_credit_6: 2110766,
  credit_fee_6: 351900,
  price_credit_12: 2356401,
  credit_fee_12: 196900,
  price_credit_15: 2481530,
  credit_fee_15: 165900,
  price_credit_18: 2607954,
  credit_fee_18: 144900,
  price_credit_24: 2865427,
  credit_fee_24: 119900,
  media: [
    {
      id: 1088,
      product_id: 886,
      type: 'image',
      embed_code: null,
      path: 'products/886/york-png-2022-04-22-15-09-42.png',
      url_youtube: null,
      priority: 1,
      deletedAt: null,
      createdAt: '2022-04-22T20:11:42.000Z',
      updatedAt: '2022-04-22T20:11:42.000Z',
    },
  ],
  brand: {
    id: 33,
    name: 'Zabra',
    description: '33',
    status: 'active',
    priority: 330,
    path_logo: 'brands/332f5de206-ff7a-4739-9f44-1d2b5b31f42b_Zabra.png',
    slug: '33-zabra',
    deletedAt: null,
    createdAt: '2022-02-09T15:02:37.000Z',
    updatedAt: '2022-02-09T15:03:09.000Z',
  },
  category: {
    id: 7,
    name: 'Muebles y Colchones',
    parent_id: 1,
    position: 1,
    path_icon: 'categories/bed.svg',
    path_image: 'categories/74b6c93c8-fb1f-4763-b685-b16dc04154a6_mueblesycolchones-5.png',
    slug: 'c-7-muebles-y-colchones',
    status: 'active',
    banner_desktop_path: 'banner_desktop_path/7d0bc7648-2512-4c22-ae33-e0c85b999971_Banners-e-comerce-7.jpg',
    banner_movile_path: 'banner_movile_path/77a4f8f86-09e9-4a60-8bac-6f81758ab5f0_Banners-e-comerce-7.jpg',
    createdAt: '2021-10-29T19:57:20.000Z',
    updatedAt: '2022-06-17T02:29:43.000Z',
    deletedAt: null,
  },
  characteristics: [],
};

const DialogContent = withStyles((theme) => ({
  root: {
    padding: `50px 90px`,
    '&:first-child': {
      paddingTop: 60,
    },
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      padding: 20,
    },
    maxWidth: 1000,
  },
}))(MuiDialogContent);

const TabPanel = (props) => {
  const {children, value, index, ...other} = props;
  if (value !== index) return <></>;
  return (
    <div
      role="tabpanel"
      style={{boxSizing: 'border-box', width: '100%', padding: 0}}
      hidden={value !== index}
      id={`product-tabpanel-${index}`}
      {...other}
    >
      {children}
    </div>
  );
};

const ProductsInterest = ({categoryId, currentProductId}) => {
  const [categories, setCategories] = useState([]);

  const getProductsByCategoryId = async (val) => {
    await api.products
      .find({
        query: {category_id: val, id: {$ne: currentProductId}, $sort: {name: 1}, $limit: 4},
      })
      .then(({data}) => {
        console.log('holl', data);
        setCategories(data);
      })
      .catch(() => undefined);
  };

  useEffect(() => {
    if (categoryId) {
      getProductsByCategoryId(categoryId);
    }
  }, [categoryId]);

  return (
    <Grid container spacing={1}>
      {categories.map((product, i) => (
        <Grid key={i} item xs={6} md={3}>
          <ItemProduct data={product} />
        </Grid>
      ))}
    </Grid>
  );
};

const ProductDetailDialog = ({handleClose, open}) => {
  const user = useSelector((state) => state.auth.user);
  const isAuth = useSelector((state) => state.auth.isAuthenticate);
  const product = useSelector(SELECTOR_PRODUCT_SELECTED);
  const units = useSelector(SELECTOR_CONFIGS_UNITS);
  const [activeTab, setActiveTab] = useState(0);

  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();

  const isMobil = useMediaQuery('(max-width:960px)');
  const ga = useGA4React();

  const [quantity, setQuantity] = useState(1);

  const handleAddedShoppingCart = () => {
    handleClose();
    const productId = getProductData(location.pathname)?.params?.p_id;
    ga.gtag('event', 'add_to_cart', {
      currency: 'COP',
      value: getPrice(
        product.discount_price_whit_tax || product.price_with_tax,
        product.price_list?.product_price_list,
      ),
      items: [
        {
          item_id: product.id,
          item_name: product.name,
          quantity,
          price: getPrice(
            product.discount_price_whit_tax || product.price_with_tax,
            product.price_list?.product_price_list,
          ),
        },
      ],
    });
    ReactPixel.track('AddToCart', {
      content_ids: [product.id.toString()],
      content_name: product.name,
      content_type: 'product',
      contents: [{id: product.id.toString(), quantity: quantity}],
      currency: 'COP',
      value: getPrice(
        product.discount_price_whit_tax || product.price_with_tax,
        product.price_list?.product_price_list,
      ),
    });
    dispatch(addToShoppingCart({productId: Number(productId), quantity}));
  };

  useEffect(() => {
    // noinspection JSUnresolvedVariable
    const productId = getProductData(location.pathname)?.params?.p_id;
    if (productId)
      dispatch(getProductById(productId)).then((p) => {
        if (p.id) {
          // api;
        }
      });
  }, [location.pathname]);

  const handleOnChangeNumeric = (value) => {
    setQuantity(value);
  };

  const _getImages = () => {
    if (!product?.media) {
      return [];
    }
    return product.media.map((item) => ({
      original: getFullPicture(item.path),
      thumbnail: getFullPicture(item.path),
    }));
  };

  const images = _getImages();

  const getPrice = (price, pricelist = []) => {
    let priceListID = user?.company?.price_list_id;

    if (priceListID && isAuth && pricelist) {
      let currentPrice = (
        Array.isArray(pricelist) ? pricelist : pricelist.price_list_id ? [pricelist] : Object.values(pricelist)
      ).find((item) => item.price_list_id === priceListID);
      return currentPrice?.price_with_tax || price;
    }

    return price;
  };

  if (!product) {
    return <div />;
  }

  return (
    <AppDialog onClose={handleClose} disableClose={true} aria-labelledby="simple-dialog-title" open={open}>
      <Helmet
        title={product.name}
        description={product.description || product.name}
        canonical={product.slug}
        og={{
          url: window.location.href,
          title: product.name,
          description: product.description || product.name,
          image: images[0]?.original,
        }}
      />
      <DialogContent>
        <IconButton className={classes.close} onClick={handleClose} aria-label="close">
          <CloseIcon fontSize="large" />
        </IconButton>
        <Grid container>
          <Grid item xs={12} md={6}>
            <Typography variant="h1" className={classes.title}>
              {product.name}
              {product.unit_measure !== 'UND' && (
                <span>
                  {' - '}
                  {units[product.unit_measure] ? units[product.unit_measure] : product.unit_measure}
                  {' x '}
                  {product.quantity_per_unit_measure} {product.quantity_per_unit_measure > 1 ? 'unidades' : 'unidad'}
                </span>
              )}
            </Typography>
            <Typography variant="h6" className={classes.subtitle}>
              Referencia: {product.code}
            </Typography>
            {isMobil && (
              <div>
                <div className={classes.imageGalleryWrapper} style={{position: 'relative'}}>
                  {product.discount_price_whit_tax && <div className={classes.tagSale}>En Descuento</div>}
                  <ImageGallery showNav={false} showPlayButton={false} items={images} />
                  {product?.quantity <= 0 && (
                    <div className={classes.opacityBackgroundWrapper}>
                      <span style={{color: 'white', fontSize: '26px'}}>AGOTADO</span>
                    </div>
                  )}
                </div>
                <Box display="flex" mt={2} pl={4}>
                  <Typography variant="h6" className={classes.subtitle}>
                    Fabricante:
                  </Typography>
                  <Link
                    to={`/b?configure%5Bpage%5D=1&configure%5Bfacets%5D%5B0%5D=category_path_ids&configure%5BfacetFilters%5D%5B0%5D=category_path_ids%3A&configure%5BnumericFilters%5D%5B0%5D=quantity%20>%3D%201&hierarchicalMenu%5Bcategories.lvl0%5D=&refinementList%5Bbrand.name%5D%5B0%5D=${product?.brand?.name}`}
                  >
                    {product?.brand?.name}
                  </Link>
                </Box>
              </div>
            )}
            {product.discount_price_whit_tax && (
              <span
                style={{
                  textDecoration: 'line-through',
                  color: 'rgb(170,170,170)',
                }}
              >
                &nbsp;
                {numeral(getPrice(product.price_with_tax, product.price_list?.product_price_list)).format('$0,0')}
                &nbsp;
              </span>
            )}
            <CurrencyFormat
              value={getPrice(
                product.discount_price_whit_tax || product.price_with_tax,
                product.price_list?.product_price_list,
              )}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
              renderText={(value) => {
                const cal = Math.round(
                  ((product.price_with_tax - product.discount_price_whit_tax) / product.price_with_tax) * 100,
                );
                return (
                  <Typography variant="body1" color="primary" className={classes.price}>
                    {product.discount_price_whit_tax ? (
                      <Badge badgeContent={cal + '%'} color="secondary">
                        ${numeral(value).format('0,0')} &nbsp;&nbsp;
                      </Badge>
                    ) : (
                      <>${numeral(value).format('0,0')} </>
                    )}
                  </Typography>
                );
              }}
            />
            {/* <Typography style={{display: 'inline-block', marginBottom: '45px'}} variant="body2" color="textPrimary" className={classes.shipments}>
            Entregas en <Typography style={{display: 'inline-block'}} variant="body2" color="textPrimary" className={classes.shipmentsBold}>Barranquilla</Typography> y <Typography style={{display: 'inline-block'}} variant="body2" color="textPrimary" className={classes.shipmentsBold}>Bogotá</Typography> el mismo día, sí tu pedido se hace antes de las <Typography style={{display: 'inline-block'}} variant="body2" color="textPrimary" className={classes.shipmentsBold}>12pm</Typography>.
          </Typography> */}
            {product.quantity <= 10 && product.quantity > 0 && (
              <Typography variant="body2" color="secondary" className={classes.quantity}>
                {`¡Últimas ${product.quantity} unidades !`}
              </Typography>
            )}
            <Typography
              variant="body1"
              color="textPrimary"
              className={classes.text}
              dangerouslySetInnerHTML={{__html: product.description}}
            />
            {product.quantity > 0 ? (
              <Box display="flex" mt={3} className={classes.actions}>
                <div style={{position: 'relative'}}>
                  <NumericInput
                    value={quantity}
                    min={1}
                    max={product.quantity}
                    step={1}
                    onChange={handleOnChangeNumeric}
                    precision={0}
                    size={product.quantity}
                    style={{
                      input: {
                        width: '100%',
                        maxWidth: '150px',
                        height: '100%',
                      },
                    }}
                    mobile
                  />
                </div>
                <AppButton
                  color="secondary"
                  round
                  disableElevation
                  variant="contained"
                  onClick={handleAddedShoppingCart}
                  className={classes.addedProductButton}
                >
                  Añadir al carrito
                </AppButton>
              </Box>
            ) : (
              <Box display="flex" mt={3} className={classes.actions}>
                No hay unidades disponibles
              </Box>
            )}
          </Grid>
          {!isMobil && (
            <Grid item xs={12} md={6}>
              <div className={classes.imageGalleryWrapper} style={{position: 'relative'}}>
                {product.discount_price_whit_tax && <div className={classes.tagSale}>En Descuento</div>}
                <ImageGallery showNav={false} showPlayButton={false} items={images} />
                {product?.quantity <= 0 && (
                  <div className={classes.opacityBackgroundWrapper}>
                    <span style={{color: 'white', fontSize: '26px'}}>AGOTADO</span>
                  </div>
                )}
              </div>
              <Box display="flex" mt={2} pl={4}>
                <Typography variant="h6" className={classes.subtitle}>
                  Fabricante:
                  <Link
                    to={`/b?configure%5Bpage%5D=1&configure%5Bfacets%5D%5B0%5D=category_path_ids&configure%5BfacetFilters%5D%5B0%5D=category_path_ids%3A&configure%5BnumericFilters%5D%5B0%5D=quantity%20>%3D%201&hierarchicalMenu%5Bcategories.lvl0%5D=&refinementList%5Bbrand.name%5D%5B0%5D=${product?.brand?.name}`}
                  >
                    {product?.brand?.name}
                  </Link>
                </Typography>
              </Box>
            </Grid>
          )}
        </Grid>
        <Box my={3}>
          <Divider />
        </Box>
        <Box mt={5} mb={3} className={classes.rootTabs}>
          <Box mb={3}>
            <Tabs
              value={activeTab}
              onChange={(_, index) => setActiveTab(index)}
              variant="scrollable"
              scrollButtons="auto"
              background="secondary"
            >
              <Tab className={classes.tabSelected} label="Especificaciones" />
              <Tab className={classes.tabSelected} label="También te puede interesar" />
              <Tab className={classes.tabSelected} label="Calificación" />
            </Tabs>
          </Box>
          <Box className={classes.tabPanelWrapper}>
            <TabPanel value={activeTab} index={1}>
              <Box mb={3} clone>
                <Typography variant="h3" align="center" className={classes.helpText}>
                  Especificaciones del producto
                </Typography>
              </Box>
              <ProductSpecifications data={objTestProduct} />
            </TabPanel>
            <TabPanel value={activeTab} index={0}>
              <Box mb={3} clone>
                <Typography variant="h3" align="center" className={classes.helpText}>
                  También te puede interesar
                </Typography>
              </Box>
              <ProductsInterest currentProductId={product.id} categoryId={product.category_id} />
            </TabPanel>
            <TabPanel value={activeTab} index={1}>
              <Box mb={3} clone>
                <Typography variant="h3" align="center" className={classes.helpText}>
                  Calificaciones de nuestros clientes
                </Typography>
              </Box>
              <ProductReviews product={objTestProduct} />
            </TabPanel>
          </Box>
        </Box>
      </DialogContent>
    </AppDialog>
  );
};

Dialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default ProductDetailDialog;
