import {
  UI_VALUE_LOADING_CHECKOUT_ADDRESS,
  UI_VALUE_LOADING_FULFILLMENT,
  UI_VALUE_SHOW_POPUP_HOME,
  UI_VALUE_SHOW_SHOPPING_CART,
  UI_VALUE_SUBSCRIBE_PRODUCT_ID,
} from '../../constants/uiConfigValues';

export const SELECTOR_UI_VALUE = (value) => (state) => state.uiConfig[value];

export const SELECTOR_UI_LOADING_CHECKOUT_ADDRESS = SELECTOR_UI_VALUE(
  UI_VALUE_LOADING_CHECKOUT_ADDRESS,
);

export const SELECTOR_UI_LOADING_FULFILLMENT = SELECTOR_UI_VALUE(
  UI_VALUE_LOADING_FULFILLMENT,
);

export const SELECTOR_UI_SHOW_SHOPPING_CART = SELECTOR_UI_VALUE(
  UI_VALUE_SHOW_SHOPPING_CART,
);

export const SELECTOR_UI_SHOW_POPUP_HOME = SELECTOR_UI_VALUE(
  UI_VALUE_SHOW_POPUP_HOME
);

export const SELECTOR_UI_MODAL_SUBSCRIBE_STOCK_PRODUCT = SELECTOR_UI_VALUE(
  UI_VALUE_SUBSCRIBE_PRODUCT_ID
)
