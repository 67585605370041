/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Logo from '../assets/images/logo.svg';
import {useDispatch, useSelector} from 'react-redux';
import {getCategoriesTree} from '../redux/actions/categories';
import Menu from 'antd/lib/menu';
import {SELECTOR_CATEGORIES_TREE} from '../redux/selectors/categories';
import {toggleShoppingCart} from '../redux/actions/uiConfig';
import Typography from '@material-ui/core/Typography';

const useStylesTextMenu = makeStyles(() => ({
  text: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
  },
}));

function itemMenu({category, classesTextMenu}) {
  if (!category.children || !category.children.length) {
    return (
      <Menu.Item key={category.slug}>
        <Typography variant={'body1'} className={classesTextMenu.text}>
          {category.name}
        </Typography>
      </Menu.Item>
    );
  }

  return (
    <Menu.SubMenu
      key={category.slug}
      icon={<div />}
      title={
        <Typography variant={'body1'} className={classesTextMenu.text}>
          {category.name}
        </Typography>
      }
    >
      {category.children.map((category) =>
        itemMenu({category, classesTextMenu}),
      )}
    </Menu.SubMenu>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'stretch',
    alignItems: 'stretch',
    minWidth: 350,
    minHeight: '100%',
    '& .ant-menu-item': {
      '&::after': {
        borderRight: `3px solid ${theme.palette.primary.main} !important`,
      },
    },
    '& .ant-menu-item-active *, & .ant-menu-submenu-selected .ant-menu-submenu-title *, & .ant-menu-submenu-active .ant-menu-submenu-title *': {
      color: `${theme.palette.primary.main} !important`,
    },
  },
  containerLogo: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 80,
  },
  containerCategories: {
    flexGrow: 1,
    marginBottom: theme.spacing(10),
  },
}));

export function MenuDrawer({open, onClose}) {
  const categoriesTree = useSelector(SELECTOR_CATEGORIES_TREE);

  const classes = useStyles();
  const classesTextMenu = useStylesTextMenu();
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => () => onClose(), []);

  useEffect(() => {
    dispatch(getCategoriesTree());
  }, []);

  function onOpenChange(categoriesPath) {
    const lvl = categoriesPath.length - 1;
    history.push(`/${categoriesPath[lvl]}`);
  }

  function onClickCategories(category) {
    history.push(`/${category.key}`);
    onClose();
  }

  function onClickMenu(menu) {
    switch (menu.key) {
      case 'MI_CUENTA':
        history.push(`/mi-cuenta`);
        onClose();
        break;
      case 'CARRITO_DE_COMPRA':
        dispatch(toggleShoppingCart(true));
        onClose();
        break;
      default:
        break;
    }
  }

  return (
    <Drawer anchor={'left'} open={open} onClose={onClose}>
      <div className={classes.root}>
        <div className={classes.containerLogo}>
          <img
            src={Logo}
            onClick={() => history.push('/')}
            className={classes.logo}
            alt="Logo Estrategias"
          />
        </div>
        <Divider />
        <div className={classes.containerCategories}>
          <Menu
            onOpenChange={onOpenChange}
            onClick={onClickCategories}
            mode="inline"
          >
            {categoriesTree.map((category) =>
              itemMenu({category, classesTextMenu}),
            )}
          </Menu>
        </div>
        <div>
          <Menu mode={'inline'} onClick={onClickMenu}>
            <Menu.Item key={'MI_CUENTA'}>
              <Typography variant={'body1'} className={classesTextMenu.text}>
                Mi cuenta
              </Typography>
            </Menu.Item>
            <Menu.Item key={'CARRITO_DE_COMPRA'}>
              <Typography variant={'body1'} className={classesTextMenu.text}>
                Carrito de compra
              </Typography>
            </Menu.Item>
          </Menu>
        </div>
      </div>
    </Drawer>
  );
}
