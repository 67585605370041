import { nanoid } from 'nanoid';
import api from '../../services/api';
import productsReducer from '../reducers/products';
import { SELECTOR_IS_AUTH } from '../selectors/auth';
import { PRODUCT_VIEWS_TOKEN, STORAGE } from '../../enviroment';
import lodash from 'lodash';
import {
  SELECTOR_PRODUCT_REVIEWS,
  SELECTOR_PRODUCT_SELECTED_ID,
} from '../selectors/products'

export function setProductSelected(id) {
  return (dispatch) => {
    dispatch(productsReducer.Creators.setProductSelect(id));
    dispatch(setProductView(id));
  };
}

export function setProducts(products) {
  return (dispatch) => {
    dispatch(productsReducer.Creators.setProducts(products));
  };
}

export function getProductById(id) {
  return async (dispatch) => {
    return api.products.get(id).then((product) => {
      dispatch(setProducts([product]));
      dispatch(setProductSelected(product.id));
      return product;
    });
  };
}

export function setProductView(id) {
  return async (dispatch, getState) => {
    const isAuth = SELECTOR_IS_AUTH(getState());
    let token = STORAGE.getItem(PRODUCT_VIEWS_TOKEN);

    if (!isAuth && !token) {
      STORAGE.setItem(PRODUCT_VIEWS_TOKEN, nanoid(32));
      token = STORAGE.getItem(PRODUCT_VIEWS_TOKEN);
    }

    if (isAuth && token) {
      // assign
      STORAGE.removeItem(PRODUCT_VIEWS_TOKEN);
      await api.userProductsViews.patch(null, {
        off_line_token: token,
      });
    }

    if (!isAuth) {
      await api.userProductsViews.create({
        product_id: id,
        off_line_token: token,
      });
    } else {
      await api.userProductsViews.create({ product_id: id });
    }
  };
}

export function getProductView() {
  return (dispatch, getState) => {
    const isAuth = SELECTOR_IS_AUTH(getState());
    let token = STORAGE.getItem(PRODUCT_VIEWS_TOKEN);
    if (!isAuth && !token) {
      STORAGE.setItem(PRODUCT_VIEWS_TOKEN, nanoid(32));
      token = STORAGE.getItem(PRODUCT_VIEWS_TOKEN);
    }
    return api.userProductsViews
      .find({
        query: {
          $client: { distinct: true },
          ...(!isAuth ? { off_line_token: token } : {}),
          $sort: { id: -1 },
          $limit: 50000,
        },
      })
      .then((it) => it.data.map((it) => it.product));
  };
}

export function getProductReviews(query = {}) {
  return (dispatch, getState) => {
    const product = SELECTOR_PRODUCT_SELECTED_ID(getState());
    const draftReviews = SELECTOR_PRODUCT_REVIEWS(getState());

    return api.productReviews
      .find({ query: lodash.merge({ $sort: { score: -1 }, $limit: 10 }, query) })
      .then(({ data: reviews, ...totals }) => {
        if (!draftReviews.find((x) => x.product_id === product)) dispatch(productsReducer.Creators.setResetReviews());
        dispatch(productsReducer.Creators.setProductReviews(reviews));
        dispatch(productsReducer.Creators.setProductReviewsTotal(totals));
        return { data: reviews, ...totals };
      });
  };
}

export function getFavoriteProducts(query = {}) {
  return (dispatch) => {
    return api.favorites
      .find(lodash.merge({ query: { $sort: { id: [-1] }, $limit: 10, type: 'product' } }, query))
      .then(({ data: favorites, ...totals }) => {
        dispatch(productsReducer.Creators.setFavorites(favorites));
        dispatch(productsReducer.Creators.setFavoritesTotals(totals));
        return { data: favorites, ...totals };
      });
  };
}
