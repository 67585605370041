/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import { isProductPage } from '../util/product';
import { useLocation } from 'react-router-dom';
import { useGA4React } from 'ga-4-react';
import { fbq } from 'react-facebook-pixel';

const useStyles = makeStyles({
  root: {
    '& .floatpse': {
      display: 'flex',
      alignItems: 'center',
      left: '4%',
    },
    '& .floatpse .floattxt': {
      padding: '15px 15px 15px 50px !important',
      marginLeft: '-40px',
      zIndex: 9,
    },
    '& .floatpse img': {
      zIndex: 9,
    },
    '& .floattxt': {
      backgroundColor: '#29A71B',
      color: 'white',
      padding: '10px 15px',
      borderRadius: '30px',
      boxShadow: '0px 3px 6px 0px rgba(0,0,0,0.09)',
      display: 'inline-block',
      fontWeight: 400,
      fontSize: 15,
    },
    '& .floatwa .MuiSvgIcon-root': {
      color: 'white',
      fontSize: '24px',
      margin: '0 7px',
      verticalAlign: 'middle',
    },

    '& .floatwa': {
      right: '4%',
    },

    '& .floatpse, .floatwa': {
      position: 'fixed',
      bottom: '8%',
      zIndex: '100',
    },
    '& @media only screen and (max-width: 996px)': {
      '& .floatwa': {
        display: 'none',
      },
    },
  },
});

ButtonWathsApp.propTypes = {
  //
};

function ButtonWathsApp(props) {
  const location = useLocation();
  const classes = useStyles();
  const ga = useGA4React();

  const currentUrl2 = window.location.href;

  const p = useMemo(() => isProductPage(location.pathname), [
    location.pathname,
  ]);

  useEffect(() => {
    if (ga) {
    }
  }, [!!ga]);

  console.log(window.gtag)


  return (
    <div className={classes.root}>
      <div className="floatwa" {...(p ? { style: { zIndex: 99 } } : {})}>
        <a
        onClick={e => { 
          window.gtag('event', 'whatsapp');
          window.fbq('track', 'Whatsapp');
        }}
          className="wa-desktop"
          href={`https://wa.me/573234793073?text=${p
            ? `Hola%20Estrategia%20Ltda,%20estoy%20interesado%20en%20el%20producto%20${currentUrl2}`
            : `%C2%A1Hola%21%20estoy%20interesado%20en%20los%20productos%20de%20Estrategias%2C%20necesito%20asesor%C3%ADa.%20%C2%A1Gracias%21`
            }`}
          target="blank"
        >
          <span className="floattxt">
            <WhatsAppIcon />
            Contáctanos
          </span>
        </a>
      </div>
    </div>
  );
}

export default ButtonWathsApp;
