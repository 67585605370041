/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import ProductDetailDialog from './ProductDetailDialog';
import {getProductData} from '../util/product';

function ProductDialog() {
  const [open, setOpen] = useState(false);

  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const data = getProductData(location.pathname);
    // noinspection JSUnresolvedVariable
    if (data?.params?.p_id && !open) {
      setOpen(true);
    } else {
      // noinspection JSUnresolvedVariable
      if (!data?.params?.p_id && open) {
        setOpen(false);
      }
    }
  }, [location.pathname]);

  function handleClose() {
    const data = getProductData(location.pathname);
    const section = data?.params?.section;
    const section2 = data?.params?.section2;
    history.push(`/${section || ''}${section2 ? `/${section2}` : ''}${location.search || ''}`);
  }

  return <ProductDetailDialog handleClose={handleClose} open={open} />;
}

export default ProductDialog;
