import {createActions, createReducer} from 'reduxsauce';
import {addList, delList, reset, setList} from '../utilities';

const INITIAL_STATE = {
  addresses: {},
  othersAddresses: {},
};

const {Types, Creators} = createActions({
  setAddresses: ['addresses'],
  addAddresses: ['addresses'],
  delAddresses: ['addresses'],
  setOthersAddresses: ['othersAddresses'],
  addOthersAddresses: ['othersAddresses'],
  delOthersAddresses: ['othersAddresses'],
  setReset: null,
});

// noinspection JSUnresolvedVariable
export const addresses = createReducer(INITIAL_STATE, {
  [Types.SET_ADDRESSES]: setList('addresses'),
  [Types.ADD_ADDRESSES]: addList('addresses'),
  [Types.DEL_ADDRESSES]: delList('addresses'),
  [Types.SET_OTHERS_ADDRESSES]: setList('othersAddresses'),
  [Types.ADD_OTHERS_ADDRESSES]: addList('othersAddresses'),
  [Types.DEL_OTHERS_ADDRESSES]: delList('othersAddresses'),
  [Types.SET_RESET]: reset(INITIAL_STATE),
});

export default {
  Types,
  Creators,
};
