import {
  ADD_NOTIFICATION,
  REMOVE_NOTIFICATION,
  SET_AUTHENTICATE,
} from '../../constants/actionTypes';

export const addNotification = (text, type) => ({
  type: ADD_NOTIFICATION,
  payload: {
    text,
    type,
  },
});

export const setAuthenticate = (value) => ({
  type: SET_AUTHENTICATE,
  payload: value,
});

export const removeNotification = (id) => ({
  type: REMOVE_NOTIFICATION,
  payload: {
    id,
  },
});
