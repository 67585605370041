import {createActions, createReducer} from 'reduxsauce';
import {addList, delList, reset, setList, setValue} from '../utilities';

const INITIAL_STATE = {
  fulfillmentSelected: 0,
  fulfillments: {},
};

const {Types, Creators} = createActions({
  setFulfillmentSelected: ['fulfillmentSelected'],
  setFulfillments: ['fulfillments'],
  addFulfillments: ['fulfillments'],
  delFulfillments: ['fulfillments'],
  setResetFulfillments: null,
  setReset: null,
});

// noinspection JSUnresolvedVariable
export const fulfillments = createReducer(INITIAL_STATE, {
  [Types.SET_FULFILLMENT_SELECTED]: setValue('fulfillmentSelected'),
  [Types.SET_FULFILLMENTS]: setList('fulfillments', 'service_code'),
  [Types.ADD_FULFILLMENTS]: addList('fulfillments', 'service_code'),
  [Types.DEL_FULFILLMENTS]: delList('fulfillments', 'service_code'),
  [Types.SET_RESET_FULFILLMENTS]: reset(INITIAL_STATE),
  [Types.SET_RESET]: reset(INITIAL_STATE),
});

export default {
  Types,
  Creators,
};
