import {createActions, createReducer} from 'reduxsauce';
import {reset} from '../utilities';
import produce from 'immer';
import { UI_VALUE_SUBSCRIBE_PRODUCT_ID } from '../../constants/uiConfigValues';

const INITIAL_STATE = {
  loadingCheckoutAddress: true,
  loadingFulfillment: true,
  showShoppingCart: false,
  showPopupHome: true,
  [UI_VALUE_SUBSCRIBE_PRODUCT_ID]: null,
};

const {Types, Creators} = createActions({
  setUiValue: ['key', 'value'],
  setReset: null,
});

const setValue = (state, {key, value}) => {
  return produce(state, (draftState) => {
    draftState[key] = value;
  });
};

// noinspection JSUnresolvedVariable
export const uiConfig = createReducer(INITIAL_STATE, {
  [Types.SET_UI_VALUE]: setValue,
  [Types.SET_RESET]: reset(INITIAL_STATE),
});

export default {
  Types,
  Creators,
};
