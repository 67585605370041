import api from '../../services/api';
import designsReducer from '../reducers/designs';

export function findDesigns(query = {}) {
  return (dispatch) => {
    return api.design.find(query).then(({data}) => {
      dispatch(designsReducer.Creators.addDesigns(data));
      return data;
    });
  };
}

export function getDesignByCode(code) {
  return (dispatch) => {
    return dispatch(findDesigns({query: {code}}));
  };
}

export function getDesignByLocation(location) {
  return (dispatch) => {
    return dispatch(findDesigns({query: {location}}));
  };
}
