import { matchPath } from 'react-router';

// export const BRAND_MATCH = 'm-:m_id-:m_name';
export const PRODUCT_MATCH = 'quick-view-:p_id-:p_name';
export const PRODUCT_ROUTES_MATCH = [
  `/${PRODUCT_MATCH}`,
  '/:section',
  // `/:section/${BRAND_MATCH}`,
  // `/:section/${BRAND_MATCH}/${PRODUCT_MATCH}`,
  `/:section/${PRODUCT_MATCH}`,
  `/:section/:section2/${PRODUCT_MATCH}`,
];

export const EXACT_PRODUCT_ROUTES_MATCH = [
  `/${PRODUCT_MATCH}`,
  '/p-:p_id-:p_name',
  `/:section/${PRODUCT_MATCH}`,
];

export function getProductData(path) {
  return matchPath(path, {
    exact: true,
    path: PRODUCT_ROUTES_MATCH,
  });
}

export function isProductPage(path) {
  return matchPath(path, {
    exact: true,
    path: EXACT_PRODUCT_ROUTES_MATCH,
  });
}

export function getProductPrice(product) {
 
  if (product.product_price_list) {
    return {
      price: product.product_price_list.price,
      price_with_tax: product.product_price_list.price_with_tax
    }
  } else if (product.discount_price) {
    return {
      price: product.discount_price,
      price_with_tax: product.discount_price_whit_tax
    }
  } else {
    return {
      price: product.price,
      price_with_tax: product.price_with_tax
    }
  }
}
