import {SELECTOR_UI_SHOW_SHOPPING_CART} from '../selectors/uiConfig';
import uiConfig from '../reducers/uiConfig';
import {
  UI_VALUE_LOADING_CHECKOUT_ADDRESS,
  UI_VALUE_LOADING_FULFILLMENT,
  UI_VALUE_SHOW_POPUP_HOME,
  UI_VALUE_SHOW_SHOPPING_CART,
  UI_VALUE_SUBSCRIBE_PRODUCT_ID,
} from '../../constants/uiConfigValues';

export function toggleShoppingCart() {
  return async (dispatch, getState) => {
    const show = SELECTOR_UI_SHOW_SHOPPING_CART(getState());
    dispatch(uiConfig.Creators.setUiValue(UI_VALUE_SHOW_SHOPPING_CART, !show));
  };
}

export function setLoadingRaw(key, loading) {
  return async (dispatch) => {
    dispatch(uiConfig.Creators.setUiValue(key, loading));
  };
}

export function setLoadingCheckoutAddress(loading) {
  return setLoadingRaw(UI_VALUE_LOADING_CHECKOUT_ADDRESS, loading);
}

export function setLoadingFulfillment(loading) {
  return setLoadingRaw(UI_VALUE_LOADING_FULFILLMENT, loading);
}

export function setShowPopupHome(loading) {
  return setLoadingRaw(UI_VALUE_SHOW_POPUP_HOME, loading);
}

export function setShowModalSubscribeStockProduct(productId) {
  return (dispatch) => {
    dispatch(uiConfig.Creators.setUiValue(UI_VALUE_SUBSCRIBE_PRODUCT_ID, productId))
  }
}
