import React from 'react';
import {Helmet as RHelmet} from 'react-helmet';
import PropTypes from 'prop-types';

Helmet.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string.isRequired,
  canonical: PropTypes.string,
  og: PropTypes.shape({
    url: PropTypes.string.isRequired,
    type: PropTypes.string,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
  }),
};

function Helmet(props) {
  return (
    <RHelmet>
      <meta charSet="utf-8" />
      <title>Estrategias{props.title ? ` | ${props.title}` : ''}</title>
      <meta name={'description'} content={props.description} />
      {props.og &&
        Object.keys(props.og).map((k, i) => (
          <meta key={i} property={`og:${k}`} content={props.og[k]} />
        ))}
      {props.canonical && (
        <link
          rel="canonical"
          href={`https://estrategias-ltda.com/${props.canonical}`}
        />
      )}
    </RHelmet>
  );
}

export default Helmet;
