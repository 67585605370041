import {createActions, createReducer} from 'reduxsauce';
import {addList, delList, reset, setList, setValue} from '../utilities';

const INITIAL_STATE = {
  companiesBudgetSelected: 0,
  companiesBudgets: {},
  companiesBudgetsTotals: {total: 0, limit: 0, skip: 0},
};

const {Types, Creators} = createActions({
  setCompaniesBudgetSelected: ['companiesBudgetSelected'],
  setCompaniesBudgets: ['companiesBudgets'],
  addCompaniesBudgets: ['companiesBudgets'],
  delCompaniesBudgets: ['companiesBudgets'],
  setCompaniesBudgetsTotals: ['companiesBudgetsTotals'],
  setResetCompaniesBudgets: null,
  setReset: null,
});

export const companiesBudgets = createReducer(INITIAL_STATE, {
  [Types.SET_COMPANIES_BUDGET_SELECTED]: setValue('companiesBudgetSelected'),
  [Types.SET_COMPANIES_BUDGETS]: setList('companiesBudgets'),
  [Types.ADD_COMPANIES_BUDGETS]: addList('companiesBudgets'),
  [Types.DEL_COMPANIES_BUDGETS]: delList('companiesBudgets'),
  [Types.SET_COMPANIES_BUDGETS_TOTALS]: setValue('companiesBudgetsTotals'),
  [Types.SET_RESET_COMPANIES_BUDGETS]: reset(INITIAL_STATE),
  [Types.SET_RESET]: reset(INITIAL_STATE),
});

export default {
  Types,
  Creators,
};
