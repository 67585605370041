import lodash from 'lodash';

export const SELECTOR_CATEGORIES_TREE = (state) =>
  state.categories.categoriesTree;

export const SELECTOR_CATEGORIES_TREE_WITH_ID = (id) => (state) => {
  if (id) {
    const tree = state.categories.categoriesTree;
    const getAllCategories = (cs) =>
      cs.map(({children: ch, ...it}) => [it, ch ? getAllCategories(ch) : []]);
    const allCategories = lodash.flattenDeep(getAllCategories(tree));
    const getCategoryPath = (cs) => [
      cs,
      cs
        ? getCategoryPath(allCategories.find((it) => it.id === cs.parent_id))
        : [],
    ];

    return lodash
      .flattenDeep(getCategoryPath(allCategories.find((it) => it.id === id)))
      .filter((it) => !!it)
      .reverse();
  } else {
    return [];
  }
};
