import { createActions, createReducer } from 'reduxsauce';
import { addList, reset, setValue } from '../utilities';

const REVIEWS_INITIAL_STATE = {
  reviews: {},
  reviewsTotal: { total: 0, limit: 0, skip: 0 },
};

const FAVORITES_INITIAL_STATE = {
  favorites: {},
  favoritesTotals: { total: 0, limit: 0, skip: 0 }
}

const INITIAL_STATE = {
  select: 0,
  products: {},
  ...REVIEWS_INITIAL_STATE,
  ...FAVORITES_INITIAL_STATE
};

const { Types, Creators } = createActions({
  setProductSelect: ['select'],
  setProducts: ['products'],
  setFavorites: ['favorites'],
  setFavoritesTotals: ['favoritesTotals'],
  setProductReviews: ['reviews'],
  setProductReviewsTotal: ['reviewsTotal'],
  setReset: null,
  setResetReviews: null,
  setResetReviewsStatistics: null,
  setResetFavorites: null,
});

// noinspection JSUnresolvedVariable
export const products = createReducer(INITIAL_STATE, {
  [Types.SET_PRODUCT_SELECT]: setValue('select'),
  [Types.SET_PRODUCTS]: addList('products'),
  [Types.SET_FAVORITES]: addList('favorites'),
  [Types.SET_FAVORITES_TOTALS]: setValue('favoritesTotals'),
  [Types.SET_PRODUCT_REVIEWS]: addList('reviews'),
  [Types.SET_PRODUCT_REVIEWS_TOTAL]: setValue('reviewsTotal'),
  [Types.SET_RESET]: reset(INITIAL_STATE),
  [Types.SET_RESET_REVIEWS]: (state) => ({ ...state, ...REVIEWS_INITIAL_STATE, }),
  [Types.SET_RESET_FAVORITES]: (state) => ({ ...state, ...FAVORITES_INITIAL_STATE })
});

export default {
  Types,
  Creators,
};
