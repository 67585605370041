import lodash from 'lodash';
import ordersReducer from '../reducers/orders';
import api from '../../services/api';

export function removeOrderDetail(id) {
  return () => {
    return api.ordersDetails.remove(id);
  };
}

export function updateOrderDetail(id, quantity) {
  return () => {
    return api.ordersDetails.patch(id, {quantity});
  };
}

export function getOrderById(id) {
  return (dispatch) => {
    dispatch(ordersReducer.Creators.setOrderSelected(Number(id)));
    return api.orders.get(id).then((order) => {
      dispatch(ordersReducer.Creators.addOrders([order]));
      // dispatch(ordersReducer.Creators.setOrderSelected(order.id));
      return order;
    });;
  };
}

export function getOrders(query = {}) {
  return (dispatch) => {
    return api.orders
      .find(lodash.merge({query: {$sort: {id: [-1]}, $limit: 10}}, query))
      .then(({data: orders, ...totals}) => {
        dispatch(ordersReducer.Creators.setOrders(orders));
        dispatch(ordersReducer.Creators.setOrdersTotals(totals));
        return {data: orders, ...totals};
      });
  };
}

export function updateOrder(id, values) {
  return (dispatch) => {
    return api.orders.patch(id, values).then((order) => {
      dispatch(ordersReducer.Creators.setOrders([order]));
      return order;
    });
  };
}

export function getOthersOrders(query = {}) {
  return (dispatch) => {
    return api.documentosPed
      .find(lodash.merge({query: {sw: 1, $sort: {id: [-1]}, $limit: 10}}, query))
      .then(({data: orders, ...totals}) => {
        dispatch(ordersReducer.Creators.setOthersOrders(orders));
        dispatch(ordersReducer.Creators.setOthersOrdersTotals(totals));
        return {data: orders, ...totals};
      });
  };
}
