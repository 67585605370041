import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const ShopIcon = (props) => {
  return (
    <SvgIcon
      {...props}
      width="23.577"
      height="25.371"
      viewBox="0 0 23.577 25.371"
    >
      <path
        id="Trazado_35"
        data-name="Trazado 35"
        d="M34.058,16.705H20.079A2.884,2.884,0,0,1,17.2,13.827V7.883a.066.066,0,0,1,.005-.031c0-.01.005-.026.005-.037s.005-.021.005-.031a.332.332,0,0,0,.01-.036c.005-.01.005-.021.01-.031s.005-.021.01-.031a.254.254,0,0,0,.016-.037.114.114,0,0,1,.016-.026l.016-.031c.005-.01.01-.016.016-.026s.016-.021.021-.031a.092.092,0,0,1,.021-.026c.005-.01.016-.016.021-.026s.016-.016.021-.026.016-.016.021-.021l.026-.026c.01-.005.016-.016.026-.021s.021-.016.031-.021.016-.01.026-.016a.109.109,0,0,0,.031-.021l.031-.016.031-.016.031-.016c.01-.005.021-.005.031-.01a.1.1,0,0,1,.036-.01.226.226,0,0,0,.026-.005.115.115,0,0,1,.042-.005c.005,0,.01-.005.021-.005L35.523,4.8V2.329a.321.321,0,0,1,.005-.073.039.039,0,0,1,.005-.021c0-.016.005-.031.005-.047s.005-.026.01-.042.005-.016.005-.026l.016-.047c0-.005.005-.016.005-.021a.156.156,0,0,1,.021-.042c.005-.005.005-.016.01-.021a.109.109,0,0,1,.021-.031c.005-.01.01-.016.016-.026s.01-.016.016-.026.016-.021.021-.031L35.7,1.86l.031-.031.016-.016a.227.227,0,0,1,.036-.031c.005-.005.016-.01.021-.016s.021-.016.031-.026a.48.48,0,0,1,.042-.026c.005-.005.01-.005.016-.01l.068-.031L39.8.056a.7.7,0,0,1,.547,1.3l-3.41,1.439V18.217a2.882,2.882,0,0,1-2.508,2.852,2.841,2.841,0,1,1-5.277,1.46,2.788,2.788,0,0,1,.391-1.434h-6.33A2.8,2.8,0,0,1,23.6,22.53a2.842,2.842,0,1,1-2.842-2.842h13.3a1.472,1.472,0,0,0,1.47-1.47V16.309A2.911,2.911,0,0,1,34.058,16.705Zm-3.5,5.819a1.434,1.434,0,1,0,1.434-1.434A1.436,1.436,0,0,0,30.554,22.524Zm-11.232,0a1.434,1.434,0,1,0,1.434-1.434A1.436,1.436,0,0,0,19.322,22.524Zm.756-7.227h13.98a1.472,1.472,0,0,0,1.47-1.47V6.219L18.608,8.55v5.272A1.473,1.473,0,0,0,20.079,15.3Z"
        transform="translate(-17.2 0)"
        fill="#e82f1b"
      />
    </SvgIcon>
  );
};

export default ShopIcon;
