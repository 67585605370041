import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import HeaderBar from '../HeaderBar';
import ProductDialog from '../ProductDialog';
import Notifications from '../Notifications';
import LoginDialog from '../LoginDialog';
import Footer from '../Footer';
import ButtonWathsApp from '../ButtonWathsApp';
import { setLoginDialogOpen } from '../../redux/actions';
import PopupHome from '../PopupHome';
import { SELECTOR_UI_MODAL_SUBSCRIBE_STOCK_PRODUCT } from '../../redux/selectors/uiConfig';
import ModalSubscribeUserProduct from '../ModalSubscribeUserProduct'
import { setShowModalSubscribeStockProduct } from '../../redux/actions/uiConfig'

BaseLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.elementType]),
};

function BaseLayout({ children }) {
  const dispatch = useDispatch();

  const productSubscribeId = useSelector(SELECTOR_UI_MODAL_SUBSCRIBE_STOCK_PRODUCT)

  const openLoginDialog = useSelector((state) => state.auth.loginDialogOpen);

  function handleCloseLoginDialog() {
    dispatch(setLoginDialogOpen(false));
  }

  return (
    <React.Fragment>
      <ModalSubscribeUserProduct
        open={productSubscribeId > 0}
        onCancel={() => dispatch(setShowModalSubscribeStockProduct(null))}
        product_id={productSubscribeId}
      />
      <HeaderBar />
      {children}
      <ProductDialog />
      <PopupHome />
      <Notifications />
      <LoginDialog open={openLoginDialog} handleClose={handleCloseLoginDialog} />
      <Footer />
      <ButtonWathsApp />
    </React.Fragment>
  );
}

export default BaseLayout;
