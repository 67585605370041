import {createActions, createReducer} from 'reduxsauce';
import {addList, delList, reset, setList, setValue} from '../utilities';

const INITIAL_STATE = {
  balance: 0,
  balance2: 0,
  balance3: 0,
  eWallet: {},
  eWalletPagination: {},
  customPayments: {},
  customPaymentsPagination: {},
};

const {Types, Creators} = createActions({
  setBalance: ['balance'],
  setBalance2: ['balance2'],
  setBalance3: ['balance3'],
  setEWallet: ['eWallet'],
  addEWallet: ['eWallet'],
  delEWallet: ['eWallet'],
  setEWalletPagination: ['eWalletPagination'],
  setCustomPayments: ['customPayments'],
  addCustomPayments: ['customPayments'],
  delCustomPayments: ['customPayments'],
  setCustomPaymentsPagination: ['customPaymentsPagination'],
  setReset: null,
});

// noinspection JSUnresolvedVariable
export const eWallet = createReducer(INITIAL_STATE, {
  [Types.SET_BALANCE]: setValue('balance'),
  [Types.SET_BALANCE2]: setValue('balance2'),
  [Types.SET_BALANCE3]: setValue('balance3'),
  [Types.SET_E_WALLET]: setList('eWallet', 'Numero'),
  [Types.ADD_E_WALLET]: addList('eWallet', 'Numero'),
  [Types.DEL_E_WALLET]: delList('eWallet', 'Numero'),
  [Types.SET_E_WALLET_PAGINATION]: setValue('eWalletPagination'),
  [Types.SET_CUSTOM_PAYMENTS]: setList('customPayments'),
  [Types.ADD_CUSTOM_PAYMENTS]: addList('customPayments'),
  [Types.DEL_CUSTOM_PAYMENTS]: delList('customPayments'),
  [Types.SET_CUSTOM_PAYMENTS_PAGINATION]: setValue('customPaymentsPagination'),
  [Types.SET_RESET]: reset(INITIAL_STATE),
});

export default {
  Types,
  Creators,
};
