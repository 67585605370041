import {
  END_LOADING_USER,
  SET_CURRENT_USER,
  SET_HAS_PENDING_SECURITY,
  SET_HAS_PENDING_USER_DATA,
  SET_LOGIN_DIALOG_OPEN,
  START_LOADING_USER,
} from '../../constants/actionTypes';
import Api from '../../services/api';
import {setAuthenticate} from './notifications';
import {ACCESS_TOKEN, STORAGE} from '../../enviroment';

export const setCurrentUser = (user) => ({
  type: SET_CURRENT_USER,
  payload: user,
});

export const startLoadingUser = () => ({
  type: START_LOADING_USER,
});

export const endLoadingUser = () => ({
  type: END_LOADING_USER,
});

export const setLoginDialogOpen = (value) => ({
  type: SET_LOGIN_DIALOG_OPEN,
  payload: value,
});

export const setHasPendingUserData = (value) => ({
  type: SET_HAS_PENDING_USER_DATA,
  payload: value,
});

export const setHasPendingSecurity = (value) => ({
  type: SET_HAS_PENDING_SECURITY,
  payload: value,
});

export const retryLoginAndGetCurrentUser = () => {
  return async (dispatch) => {
    if (STORAGE.getItem(ACCESS_TOKEN)) {
      try {
        const {user} = await Api.retryLogin();
        dispatch(setCurrentUser(await Api.users.get(user.id)));
        dispatch(setAuthenticate(true));
      } catch (e) {
        dispatch(setAuthenticate(false));
      }
    } else {
      dispatch(setAuthenticate(false));
    }
  };
};

export const getCurrentUser = () => {
  return async (dispatch) => {
    dispatch(startLoadingUser());
    try {
      const data = await Api.current();
      dispatch(setCurrentUser(data));
    } catch (e) {
    } finally {
      dispatch(endLoadingUser());
    }
  };
};
