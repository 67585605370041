import api from '../../services/api';
import {toggleShoppingCart} from './uiConfig';
import {addNotification} from './notifications';
import {SHOPPING_CART_ID, SHOPPING_CART_TOKEN, STORAGE} from '../../enviroment';
import {SELECTOR_IS_AUTH} from '../selectors/auth';
import shoppingCart from '../reducers/shoppingCart';
import {Mutex} from '../../util/mutex';

const mutex = new Mutex();

export function getShoppingCart(forceAssign) {
  return async (dispatch, getState) => {
    return mutex.dispatch(async () => {
      const state = getState();
      const isAuth = SELECTOR_IS_AUTH(state);

      async function getCart() {
        const token = STORAGE.getItem(SHOPPING_CART_TOKEN);

        const cart = await api.shoppingCart
          .find({
            query: {
              $eager: '[shopping_cart_details]',
              $client: {products: 'true'},
              status: 'active',
              $sort: {
                id: -1,
              },
              $limit: 1,
              ...(token ? {token} : {}),
            },
          })
          .then(({data}) => data[0]);

        if ((isAuth || forceAssign) && token && cart) {
          await api.shoppingCart.patch(cart.id, {});
          STORAGE.removeItem(SHOPPING_CART_TOKEN);
          STORAGE.removeItem(SHOPPING_CART_ID);
        } else if (isAuth && token && !cart) {
          STORAGE.removeItem(SHOPPING_CART_TOKEN);
          STORAGE.removeItem(SHOPPING_CART_ID);
          return getCart();
        }

        return cart;
      }

      const cart = await getCart();

      if (cart) {
        // noinspection JSUnresolvedVariable
        const products = cart.shopping_cart_details || [];
        const products_related = cart.relate_products || [];

        dispatch(shoppingCart.Creators.setShoppingCartId(cart.id));
        dispatch(shoppingCart.Creators.setShoppingCartProducts(products));
        dispatch(shoppingCart.Creators.setRelateProducts(products_related));
      } else {
        dispatch(shoppingCart.Creators.setShoppingCartId(undefined));
        dispatch(shoppingCart.Creators.setShoppingCartProducts([]));
        dispatch(shoppingCart.Creators.setRelateProducts([]));
      }

      return cart;
    });
  };
}

export function addToShoppingCart({productId, quantity}) {
  return async (dispatch) => {
    const scToken = STORAGE.getItem(SHOPPING_CART_TOKEN);

    try {
      if (scToken) {
        await api.shoppingCartDetails
          .create({
            product_id: productId,
            quantity: quantity,
            token: scToken,
          })
          .catch(async () => {
            const {token, id} = await api.shoppingCart.create({
              product_id: productId,
              quantity: quantity,
            });
            STORAGE.setItem(SHOPPING_CART_TOKEN, token);
            STORAGE.setItem(SHOPPING_CART_ID, id);
            return null;
          });
        dispatch(toggleShoppingCart(true));
      } else {
        try {
          const {token, id} = await api.shoppingCart.create({
            product_id: productId,
            quantity: quantity,
          });
          if (token) STORAGE.setItem(SHOPPING_CART_TOKEN, token);
          STORAGE.setItem(SHOPPING_CART_ID, id);
          dispatch(toggleShoppingCart(true));
        } catch (e) {
          try {
            const {shoppingCartExist} = await api.shoppingCartDetails.create({
              product_id: productId,
              quantity: quantity,
            });
            if (shoppingCartExist) {
              changeCountItemInShoppingCart(productId, quantity);
            }
            dispatch(toggleShoppingCart(true));
          } catch (e) {
            dispatch(addNotification(e.message, 'error'));
          }
        }
      }
    } catch (e) {
      dispatch(addNotification(e.message, 'error'));
    }
  };
}

export const removeItemInShoppngCart = (id) => {
  return async (dispatch) => {
    const scToken = STORAGE.getItem(SHOPPING_CART_TOKEN);
    let payload = {};
    try {
      if (scToken && scToken !== 'null' && scToken !== 'undefined' && scToken !== '') {
        payload = {
          '$client[token]': scToken,
        };
      }
      await api.shoppingCartDetails.remove(id, {query: payload});
      dispatch(addNotification('¡Producto removido del carrito con éxito!', 'info'));
    } catch (e) {
      dispatch(addNotification(e.message, 'error'));
    }
  };
};

export const changeCountItemInShoppingCart = (id, count) => {
  return async (dispatch) => {
    const scToken = STORAGE.getItem(SHOPPING_CART_TOKEN);
    let payload = {};
    try {
      if (scToken) {
        payload = {
          token: scToken,
        };
      }
      await api.shoppingCartDetails.patch(id, {
        quantity: count,
        ...payload,
      });
    } catch (e) {
      dispatch(addNotification(e.message, 'error'));
    }
  };
};

export function resetShoppingCart() {
  return (dispatch) => {
    dispatch(shoppingCart.Creators.setResetShoppingCart());
  };
}

export function createCartFromOrderId(orderId) {
  return async (dispatch) => {
    await api.shoppingCart
      .create({}, {query: {$client: {createFromOrderId: orderId}}})
      .then(() => {
        dispatch(getShoppingCart());
        dispatch(toggleShoppingCart(true));
      })
      .catch((err) => {
        dispatch(addNotification(err?.message, 'error'));
        dispatch(getShoppingCart());
        dispatch(toggleShoppingCart(true));
      });
  };
}
