
import {makeStyles, withStyles} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import React from 'react';
import Slide from '@material-ui/core/Slide';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CustomDialog = withStyles((theme) => ({
  paper: (props) => ({
    maxWidth: props.maxWidth ? props.maxWidth : 1000,
    width: '95%',
    borderRadius: 25,
    [theme.breakpoints.down('sm')]: {
      margin: 10,
    },
  }),
}))(
  React.forwardRef(({maxWidth, ...props}, ref) => (
    <Dialog ref={ref} {...props} />
  )),
);

const useStyles = makeStyles((theme) => ({
  close: {
    position: 'absolute',
    top: 20,
    right: 30,
    [theme.breakpoints.down('sm')]: {
      top: 10,
      right: 20,
    },
  },
}));

const AppDialog = ({children, handleClose, disableClose, ...other}) => {
  const classes = useStyles();

  return (
    <CustomDialog
      TransitionComponent={Transition}
      handleClose={() => {}}
      {...other}
    >
      {!disableClose && (
        <IconButton
          className={classes.close}
          onClick={handleClose}
          aria-label="close"
        >
          <CloseIcon fontSize="large" />
        </IconButton>
      )}
      {children}
    </CustomDialog>
  );
};

export default AppDialog;
