/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import AppDialog from './AppDialog';
import DialogContent from '@material-ui/core/DialogContent';
import PhoneInput from 'react-phone-input-2';
import Box from '@material-ui/core/Box';
import AppButton from './AppButton';
import Typography from '@material-ui/core/Typography';
import OtpInput from 'react-otp-input';
import Divider from '@material-ui/core/Divider';
import { Form, Formik } from 'formik';
import Grid from '@material-ui/core/Grid';
import api from '../services/api';
import { addNotification, setAuthenticate } from '../redux/actions';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { retryLoginAndGetCurrentUser, setLoginDialogOpen } from '../redux/actions';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { ACCESS_TOKEN, STORAGE } from '../enviroment';
import { SELECTOR_USER, SELECTOR_USER_PENDING, SELECTOR_USER_PENDING_SECURITY } from '../redux/selectors/auth';
import { makeStyles } from '@material-ui/core/styles';
import Text from './fields/Text';
import Radio from './fields/Radio';
import Checkbox from './fields/Checkbox';
import { listado } from '../util/listado';
import { FormControl, InputLabel, MenuItem, Select as MatSelect, alpha } from '@material-ui/core';

export const STATE_OTP_LOGIN_EMAIL = 'OTP_LOGIN_EMAIL';
export const STATE_OTP_LOGIN_PHONE = 'OTP_LOGIN_PHONE';
export const STATE_OTP_LOGIN_CODE_PHONE = 'OTP_LOGIN_CODE_PHONE';
export const STATE_OTP_LOGIN_CODE_EMAIL = 'OTP_LOGIN_CODE_EMAIL';
export const STATE_OTP_REGISTER = 'OTP_REGISTER';
export const STATE_INITIAL = 'STATE_INITIAL'

const useStylesFormLogin = makeStyles((theme) => ({
  label: (props) => ({
    fontSize: '.9rem',
    fontWeight: props.labelBold ? 700 : 300,
    marginBottom: theme.spacing(1),
  }),
}));

const useStylesReddit = makeStyles((theme) => ({
  root: {
    border: '1px solid #e2e2e1',
    overflow: 'hidden',
    borderRadius: 4,
    backgroundColor: '#fcfcfb',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:hover': {
      backgroundColor: '#fff',
    },
    '&$focused': {
      backgroundColor: '#fff',
      boxShadow: `${alpha(theme.palette.primary.main, 0.2)} 0 0 0 2px`,
      borderColor: theme.palette.primary.main,
    },
  },
}));

export function FormLogin({ stateForm, setStateForm }) {
  const [code, setCode] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [countryCode, setCountryCode] = useState('');

  const dispatch = useDispatch();
  const classes = useStylesFormLogin();

  function onChangePhoneCode(phone, country) {
    setPhone(phone);
    setCountryCode(country.dialCode);
  }

  function onChangeEmail(e) {
    setEmail(e.target.value);
  }

  function onSubmitLogin(sendCode) {
    let payload = {};

    switch (stateForm) {
      case STATE_OTP_LOGIN_PHONE:
      case STATE_OTP_LOGIN_CODE_PHONE:
        payload = {
          strategy: 'sms',
          phone: phone.substr(countryCode.length),
          phone_country_code: countryCode,
        };
        break;
      case STATE_OTP_LOGIN_EMAIL:
      case STATE_OTP_LOGIN_CODE_EMAIL:
        payload = {
          strategy: 'email',
          email,
        };
      // eslint-disable-next-line no-fallthrough
      default:
        break;
    }

    if (sendCode) {
      const ck = stateForm === STATE_OTP_LOGIN_CODE_PHONE ? 'phone' : 'email';
      payload[`token_login_${ck}`] = code;
    }

    api.authentication
      .create(payload)
      .then(({ accessToken, user, message }) => {
        if ([STATE_OTP_LOGIN_EMAIL, STATE_OTP_LOGIN_PHONE].includes(stateForm)) {
          const next = stateForm === STATE_OTP_LOGIN_EMAIL ? STATE_OTP_LOGIN_CODE_EMAIL : STATE_OTP_LOGIN_CODE_PHONE;
          dispatch(addNotification(message, 'success'));
          setStateForm(next);
        } else if (stateForm === STATE_OTP_LOGIN_CODE_EMAIL && !sendCode) {
          dispatch(addNotification('Se envió el código por medio de un email.', 'success'));
        } else {
          STORAGE.setItem(ACCESS_TOKEN, accessToken);

          dispatch(retryLoginAndGetCurrentUser());

          if (user.status !== 'pending user data') {
            dispatch(setLoginDialogOpen(false));
          }
          setStateForm(STATE_OTP_REGISTER);
        }
      })
      .catch((error) => {
        dispatch(addNotification(error.message, 'error'));
      });
  }

  return (
    <React.Fragment>
      {[STATE_OTP_LOGIN_PHONE, STATE_OTP_LOGIN_CODE_PHONE].includes(stateForm) && (
        <React.Fragment>
          <Typography color="textPrimary" className={classes.label}>
            Celular
          </Typography>
          <PhoneInput
            enableSearch
            masks={{ co: '(...) ... ....' }}
            country={'co'}
            specialLabel={''}
            placeholder={'Celular'}
            value={phone}
            onChange={onChangePhoneCode}
            inputStyle={{
              width: '100%',
              border: '1px solid #e2e2e1',
              backgroundColor: '#fcfcfb',
              height: 58,
            }}
            containerStyle={{
              width: '100%',
            }}
          />
        </React.Fragment>
      )}
      {[STATE_OTP_LOGIN_EMAIL, STATE_OTP_LOGIN_CODE_EMAIL].includes(stateForm) && (
        <Text name={'email'} label="Correo electrónico" value={email} onChange={onChangeEmail} />
      )}

      {/*<Box mt={2} mb={3} display="flex" justifyContent="flex-end">
        <Button color="primary" onClick={toggleForm}>
          o ingresar con tu {stateForm === STATE_OTP_LOGIN_PHONE ? 'email' : 'celular'}
        </Button>
      </Box>*/}

      <Box mt={3} display="flex" justifyContent="center">
        <AppButton round onClick={() => onSubmitLogin(false)} disableElevation variant="contained" color="secondary">
          Enviar código
        </AppButton>
      </Box>
      <Box mt={3} display="flex" justifyContent="center">
        <AppButton round onClick={() => setStateForm(STATE_INITIAL)} disableElevation variant="outlined" color="primary">
          Probar otro método
        </AppButton>
      </Box>
      {[STATE_OTP_LOGIN_CODE_PHONE, STATE_OTP_LOGIN_CODE_EMAIL].includes(stateForm) && (
        <React.Fragment>
          <Box mb={3} clone>
            {
              stateForm === STATE_OTP_LOGIN_CODE_PHONE
                ? <>
                  <Typography align="center">Te hemos enviado un código por SMS</Typography>
                  <Typography align="center">Dependiendo de tu operador puede tardar hasta 1 minuto.</Typography>
                </>
                : <>
                  <Typography align="center">Te hemos enviado un código por e-mail</Typography>
                  <Typography align="center">No te olvides de revisar la bandeja Spam.</Typography>
                </>
            }
          </Box>

          <OtpInput
            containerStyle={{ justifyContent: 'center' }}
            onChange={(otp) => setCode(otp)}
            numInputs={4}
            value={code}
            inputStyle={{
              color: '#000',
              padding: 14,
              boxSizing: 'content-box',
              margin: '0 20px',
            }}
            separator={<span>-</span>}
          />

          <Box mt={3} display="flex" justifyContent="center">
            <AppButton
              disabled={code.length !== 4}
              round
              onClick={() => onSubmitLogin(true)}
              disableElevation
              variant="contained"
              color="secondary"
            >
              Verificar
            </AppButton>
          </Box>

          <Box clone my={3}>
            <Divider />
          </Box>
          {/*<Typography align="center">El codigo se reenviara automaticamente en: 20 </Typography>*/}
        </React.Fragment>
      )}
      <Box mb={7} />
    </React.Fragment>
  );
}

const validationRegisterForm = yup.object().shape({
  name: yup.string().max(156, 'Nombre es demasiado largo').required('Nombre es requerido.'),
  lastName: yup.string().max(156, 'Apellido es demasiado largo').required('Apellido es requerido.'),
  dni: yup.number().required('Cédula es requerido.').typeError("debe de ingresar un valor valido."),
  email: yup.string().email('Ingrese un correo electrónico valido').required('Correo electrónico es requerido.'),
  phone: yup.string().required('Celular es requerido.'),
  personType: yup.string()
    .required('Campo requerido.')
    .oneOf(['legal', 'natural'])
    .typeError("este campo es obligatorio para el registro."),
  companyName: yup.string().when('personType', {
    is: 'legal',
    then: yup
      .string()
      .required('Campo requerido.')
      .typeError("debe de ingresar un valor valido.")
      .max(156, 'ka razón social es demasiado larga.'),
  }),
  nit: yup.number().when('personType', {
    is: 'legal',
    then: yup
      .number()
      .integer('Ingrese el NIT sin puntos ni espacios')
      .required('Campo requerido.')
      .typeError("Ingrese el NIT sin puntos ni espacios")
      .min(0, 'debe de colocar un valor superior a cero.')
      .transform((value, originalValue) => (/\s/.test(originalValue) ? NaN : value))
  }),
  dv: yup.number().when('personType', {
    is: 'legal',
    then: yup
      .number()
      .required('requerido.')
      .typeError("debe de ingresar un valor valido.")
      .min(0, 'debe de colocar un valor superior a cero.'),
  }),
});

const useStylesFormRegister = makeStyles((theme) => ({
  label: (props) => ({
    fontSize: '.9rem',
    fontWeight: props.labelBold ? 700 : 300,
    marginBottom: theme.spacing(1),
  }),
}));

export function FormRegister() {
  const user = useSelector(SELECTOR_USER);

  const dispatch = useDispatch();
  const classes = useStylesFormRegister();

  const classesReddit = useStylesReddit();

  async function onSubmit(values, { resetForm }) {
    const {
      companyName,
      name,
      dv,
      autoretenedor,
      gran_contribuyente,
      personType,
      lastName,
      email,
      phone_country_code,
      phone,
      dni,
      nit,
      customer_type,
    } = values;

    let payload = {
      email,
      phone,
      phone_country_code,
    };

    if (personType !== 'natural') {
      payload = {
        ...payload,
        nit: nit.toString(),
        autoretenedor: String(autoretenedor),
        company_name: companyName,
        gran_contribuyente: String(gran_contribuyente),
        dni_digit: dv.toString(),
      };
    }

    try {
      await api.users.patch(user.id, {
        first_name: name,
        last_name: lastName,
        dni: dni.toString(),
        person_type: personType,
        customer_type,
        ...payload,
      });
      resetForm();
      dispatch(retryLoginAndGetCurrentUser());
      dispatch(setAuthenticate(true));
      dispatch(setLoginDialogOpen(false));
      dispatch(addNotification('Bienvenido !', 'success'));
    } catch (e) {
      dispatch(addNotification(e.message, 'error'));
    } finally {
      window.location.reload();
    }

  }

  function logout() {
    STORAGE.clear();
    dispatch({ type: 'SET_RESET' });
    window.location.href = '/';
  }

  return (
    <Box mb={3}>
      <Formik
        enableReinitialize
        initialValues={{
          companyName: '',
          email: user.email || '',
          phone: user.phone || '',
          nit: user.nit || '',
          dni: user.dni || '',
          name: user.first_name || '',
          lastName: user.last_name || '',
          phone_country_code: user.phone_country_code || '',
          autoretenedor: false,
          gran_contribuyente: false,
          personType: user.person_type || '',
          customer_type: user.customer_type || '',
        }}
        validationSchema={validationRegisterForm}
        onSubmit={onSubmit}
        render={({ values, handleChange, handleSubmit, isSubmitting, setFieldValue, errors }) => (
          <Form
            onKeyPress={(event) => {
              event.key === 'Enter' && handleSubmit();
            }}
          >
            <Grid container spacing={3}>
              <Grid item md={12}>
                <Text xt name={'name'} label="Nombres" value={values.name} onChange={handleChange} error={!!errors.name} helperText={errors.name} />
              </Grid>
              <Grid item md={12}>
                <Text name={'lastName'} label="Apellidos" value={values.lastName} onChange={handleChange} error={!!errors.lastName} helperText={errors.lastName} />
              </Grid>
              <Grid item xs={12}>
                <Text name={'dni'} label="Cédula" value={values.dni} onChange={handleChange} error={!!errors.dni} helperText={errors.dni} />
              </Grid>
              <Grid item xs={12}>
                <Box mb={2}>
                  <FormControl fullWidth variant="filled">
                    <InputLabel htmlFor="filled-age-native-simple">Selecciona a que sector perteneces</InputLabel>
                    <MatSelect native disableUnderline className={classesReddit.root} value={values.customer_type} defaultValue={values.customer_type} onChange={(event) => {
                      setFieldValue('customer_type', event.target.value);
                    }}>
                      <option aria-label="None" value="">Selecciona a que sector perteneces</option>
                      {Object.entries(listado).map(([category, items]) => (
                        <optgroup label={category} key={category}>
                          {items.map((item, index) => (
                            <option value={`${category}-${item}`} key={`${category}-${index}`}>
                              {item}
                            </option>
                          ))}
                        </optgroup>
                      ))}
                    </MatSelect>
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Typography color="textPrimary" className={classes.label}>
                  Celular
                </Typography>
                <PhoneInput
                  enableSearch
                  masks={{ co: '(...) ... ....' }}
                  country={'co'}
                  specialLabel={''}
                  value={values.phone_country_code + values.phone}
                  onChange={(phone, country) => {
                    setFieldValue('phone', phone.substr(country.dialCode.length));
                    setFieldValue('phone_country_code', country.dialCode);
                  }}
                  inputStyle={{
                    width: '100%',
                    border: '1px solid #e2e2e1',
                    backgroundColor: '#fcfcfb',
                    height: 58,
                  }}
                  containerStyle={{
                    width: '100%',
                  }}
                  {...(user.phone ? { disabled: true } : {})}
                />
              </Grid>
              <Grid item xs={12}>
                <Text
                  name={'email'}
                  label="Correo electrónico"
                  value={values.email}
                  error={!!errors.email}
                  helperText={errors.email}
                  onChange={handleChange}
                  {...(user.email ? { disabled: true } : {})}
                />
              </Grid>
              <Grid item md={12}>
                <Radio
                  columns={2}
                  label={'¿Compras a nombre de una empresa?'}
                  name={'personType'}
                  options={[
                    { label: 'Si', value: 'legal' },
                    { label: 'No', value: 'natural' },
                  ]}
                  value={values.personType}
                  onChange={handleChange}
                />
              </Grid>
              {values.personType === 'legal' && (
                <React.Fragment>
                  <Grid item md={12}>
                    <Text
                      name={'companyName'}
                      label="Razón social"
                      value={values.companyName}
                      onChange={handleChange}
                      error={!!errors.companyName}
                      helperText={errors.companyName}
                    />
                  </Grid>
                  <Grid item md={8}>
                    <Text name={'nit'} label="NIT" value={values.nit} onChange={handleChange} error={!!errors.nit}
                      helperText={errors.nit} />
                  </Grid>
                  <Box clone display="flex" justifyContent="center" alignItems="center">
                    <Grid item md={1}>
                      -
                    </Grid>
                  </Box>
                  <Grid item md={3}>
                    <Text name={'dv'} label="DV" value={values.dv} onChange={handleChange} error={!!errors.dv}
                      helperText={errors.dv} />
                  </Grid>
                  <Grid item xs={12}>
                    <Checkbox
                      columns={2}
                      name={'personType2'}
                      options={[
                        { label: 'Auto retenedor', value: 'autoretenedor' },
                        { label: 'Gran contribuyente', value: 'gran_contribuyente' },
                      ]}
                      value={{ autoretenedor: values.autoretenedor, gran_contribuyente: values.gran_contribuyente }}
                      onChange={(values) => {
                        setFieldValue('autoretenedor', values.autoretenedor);
                        setFieldValue('gran_contribuyente', values.gran_contribuyente);
                      }}
                    />
                  </Grid>
                </React.Fragment>
              )}
            </Grid>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mt={3}
            >
              <AppButton
                type="submit"
                round
                disableElevation
                variant="contained"
                color="primary"
                size="lg"
                onClick={handleSubmit}
                disabled={isSubmitting}
              >
                Regístrate
              </AppButton>
              <AppButton
                type="button"
                round
                disableElevation
                variant="outlined"
                color="secondary"
                size="lg"
                onClick={logout}
              >
                Cerrar sesión
              </AppButton>
            </Box>
          </Form>
        )}
      />
    </Box>
  );
}

function LoginDialog({ open }) {
  const user = useSelector(SELECTOR_USER);
  const pendingSecurity = useSelector(SELECTOR_USER_PENDING_SECURITY);
  const pending = useSelector(SELECTOR_USER_PENDING);

  // const [stateForm, setStateForm] = useState(STATE_INITIAL);
  const [stateForm, setStateForm] = useState(STATE_INITIAL);

  const history = useHistory();
  const dispatch = useDispatch();
  const matchCheckout = useRouteMatch('/checkout/:section');

  function handleClose() {
    if (!matchCheckout) {
      history.push('/');
    }
    dispatch(setLoginDialogOpen(false));
  }

  useEffect(() => {
    if (user?.status === 'pending user data') {
      setStateForm(STATE_OTP_REGISTER);
    }
  }, [user?.id]);

  return (
    <AppDialog
      disableClose={pending}
      maxWidth={500}
      handleClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <Box pt={3}>
        <DialogContent>
          {stateForm === STATE_INITIAL && !pendingSecurity ? (
            <Box style={{ textAlign: 'center' }} mt={3}>
              <Typography color="primary" align="center" variant="h6">
                Inicia sesión
              </Typography>
              <Box mb={2} mt={4}>
                <AppButton round onClick={() => setStateForm(STATE_OTP_LOGIN_EMAIL)} disableElevation variant="contained" color="primary">
                  Ingresa con tu email
                </AppButton>
              </Box>
              <Box mb={3}>
                <AppButton round onClick={() => setStateForm(STATE_OTP_LOGIN_PHONE)} disableElevation variant="contained" color="primary">
                  Ingresa con número de teléfono
                </AppButton>
              </Box>
            </Box>
          ) : (
            <>
              {pendingSecurity ? (
                <Box mb={5} mt={4}>
                  <Box mb={2}>
                    <Typography color="secondary" align="center" variant="h6">
                      Pendiente de verificación de seguridad
                    </Typography>
                  </Box>
                  <Typography color="primary" align="center">
                    EL NIT ingresado pertenece a una compañía existente dentro de nuestro sistema por lo que primero debemos
                    verificar tu cuenta para luego asociarla a la compañía correspondiente. <br /> <br /> Te estaremos
                    llamando para realizar dicha verificación o puedes contactarnos por Whatsapp o llamada +57 323 4793073
                    para agilizar el proceso.
                  </Typography>
                </Box>
              ) : (
                <React.Fragment>
                  <Box clone mb={3}>
                    <Typography color="secondary" align="center" variant="h6">
                      {stateForm === STATE_OTP_REGISTER ? 'Completa tus datos' : 'Regístrate o ingresa ahora'}
                    </Typography>
                  </Box>
                  {stateForm === STATE_OTP_REGISTER ? (
                    <FormRegister stateForm={stateForm} />
                  ) : (
                    <FormLogin stateForm={stateForm} setStateForm={(state) => setStateForm(state)} />
                  )}
                </React.Fragment>
              )}
            </>
          )}

        </DialogContent>
      </Box>
    </AppDialog>
  );
}

export default LoginDialog;
