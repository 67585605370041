import {createActions, createReducer} from 'reduxsauce';
import {reset, setValue, addList, setList} from '../utilities';

const INITIAL_STATE = {
  shoppingCartId: 0,
  products: {},
  relateProducts: [],
};

const {Types, Creators} = createActions({
  setShoppingCartId: ['shoppingCartId'],
  addShoppingCartProducts: ['products'],
  setShoppingCartProducts: ['products'],
  setRelateProducts: ['relateProducts'],
  setResetShoppingCart: null,
  setReset: null,
});

// noinspection JSUnresolvedVariable
export const shoppingCart = createReducer(INITIAL_STATE, {
  [Types.SET_SHOPPING_CART_ID]: setValue('shoppingCartId'),
  [Types.ADD_SHOPPING_CART_PRODUCTS]: addList('products'),
  [Types.SET_SHOPPING_CART_PRODUCTS]: setList('products'),
  [Types.SET_RELATE_PRODUCTS]: setList('relateProducts'),
  [Types.SET_RESET_SHOPPING_CART]: reset(INITIAL_STATE),
  [Types.SET_RESET]: reset(INITIAL_STATE),
});

export default {
  Types,
  Creators,
};
