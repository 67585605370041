import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';
import CurrencyFormat from 'react-currency-format';
import NumericInput from 'react-numeric-input';
import clsx from 'clsx';
import { Chip } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  image: {
    width: 64,
    height: 77,
  },
  title: {
    maxWidth: 150,
    fontSize: '.6rem',
    fontWight: 500,
    textTransform: 'uppercase',
  },
  price: {
    fontWeight: 700,
    fontSize: '.6rem',
  },
  info: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  button: {
    fontWeight: 300,
    fontSize: '.8rem',
  },
  disabled: {
    filter: 'blur(1px)',
  },
  tag: {
    backgroundColor: '#1374ee',
    color: 'white',
    fontWeight: 'normal',
    fontSize: '12px',
    borderRadius: '5px',
    padding: '2px 6px',
  }
}));

const ShoppingCartCard = ({
  title,
  image,
  price,
  count,
  max,
  handleOnChangeNumeric,
  handleDelete,
  status,
  quantity,
  oversize
}) => {
  const classes = useStyles();

  return (
    <Box mb={2} display="flex">
      <img src={image} alt="product" className={clsx({
        [classes.image]: true,
        [classes.disabled]: status === 'deleted' || quantity <= 0
      })} />
      <Box className={classes.info}>
        {oversize === 'true' ? <span className={classes.tag}>Oversize</span> : null}
        <Typography color="textPrimary" className={classes.title}>
          {title}
        </Typography>

        <Box mt={1} display="flex" alignItems="center">
          {status === 'active' && quantity > 0
            ? (
              <NumericInput
                value={count}
                min={1}
                max={max}
                step={1}
                onChange={handleOnChangeNumeric}
                precision={0}
                disable
                style={{
                  input: {
                    width: '100%',
                    maxWidth: '110px',
                  },
                }}
                size={max}
                mobile
              />
            )
            : (
              <Box>
                <Typography variant="caption" color="error">Producto no disponible</Typography>
              </Box>
            )
          }
          <Button
            variant="small"
            onClick={handleDelete}
            className={classes.button}
            startIcon={<DeleteIcon fontSize="small" />}
          >
            Eliminar
          </Button>
        </Box>
      </Box>
      {status === 'active' && quantity > 0 && (
        <CurrencyFormat
          value={price || 0}
          displayType={'text'}
          thousandSeparator={true}
          prefix={'$'}
          renderText={(value) => (
            <Typography className={classes.price}>{value}</Typography>
          )}
        />
      )}
    </Box>
  );
};

export default ShoppingCartCard;
