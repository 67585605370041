import configsReducers from '../reducers/configs';
import api from '../../services/api';

export function getUnits() {
  return async (dispatch) => {
    return api.configurations.get(13).then((units) => {
      dispatch(
        configsReducers.Creators.setConfigsUnits(JSON.parse(units.value)),
      );
    });
  };
}
