import {SET_CURRENT_CATEGORY} from '../../constants/actionTypes';

const initialState = {
  currentCategory: -1,
  showCategorySearchBar: false,
  currentBranch: -1,
};

export default (state = initialState, {type, payload}) => {
  switch (type) {
    case SET_CURRENT_CATEGORY:
      return {
        ...state,
        currentCategory: payload,
        showCategorySearchBar: payload !== -1,
      };
    default:
      return state;
  }
};
