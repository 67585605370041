import { Box, Button, Container, Typography } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import React from 'react'
import { useContext } from 'react'
import { CommercialContext } from '../context/commercial.context'
import AlertTitle from '@material-ui/lab/AlertTitle'
import PersonOutline from '@material-ui/icons/PersonOutline'
import ModalChangeProfile from './ModalChangeProfile'

const HeaderBanner = () => {

    const { state, loading, changeProfile } = useContext(CommercialContext)

    return <div>
        <Container>
            <Alert severity='warning' action={
                <Button loading={loading} onClick={changeProfile} color="secondary">
                    <PersonOutline /> {loading ? 'Espere...' : (state?.ownerToken ? 'Volver a mi perfil' : 'Cambiar perfil')}
                </Button>
            }>
                <AlertTitle>
                    <Typography variant='body2' component="h4" >Estas actuando en nombre de:</Typography>
                </AlertTitle>
                <Box>
                    <Typography>
                        {state?.user?.first_name} {state?.user?.last_name} - {state?.user?.company?.name}
                    </Typography>
                </Box>
                <Typography variant="caption">El perfil comercial está activo</Typography>
            </Alert>
        </Container>
        <ModalChangeProfile />
    </div>
}

export default HeaderBanner