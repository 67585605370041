import categoriesReducers from '../reducers/categories';
import api from '../../services/api';

export function getCategoriesTree() {
  return async (dispatch) => {
    return api.categories
      .find({query: {$client: {tree: true}}})
      .then(({data: categories}) => {
        dispatch(categoriesReducers.Creators.setCategoriesTree(categories));
      });
  };
}
