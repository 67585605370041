import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import MatRadio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  root: {
    flexDirection: 'row',
  },
  contentRadio: ({columns}) => {
    return {
      display: 'grid',
      gridTemplateColumns: `repeat(${columns}, 1fr)`,
    };
  },
}));

Radio.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  columns: PropTypes.number,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      label: PropTypes.string.isRequired,
    }).isRequired,
  ),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onChange: PropTypes.func,
};

function Radio({columns, label, name, options, value, onChange}) {
  const classes = useStyles({columns: columns || 1});

  return (
    <FormControl component="fieldset" className={classes.root}>
      {label && <FormLabel component="legend">{label}</FormLabel>}
      <RadioGroup aria-label={name} className={classes.contentRadio} name={name} value={value} onChange={onChange}>
        {options.map((it, i) => (
          <FormControlLabel key={i} control={<MatRadio />} {...it} />
        ))}
      </RadioGroup>
    </FormControl>
  );
}

export default Radio;
