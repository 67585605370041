import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { SELECTOR_USER } from '../../../redux/selectors/auth'
import { CommercialContext } from '../context/commercial.context'
import HeaderBanner from '../ui/HeaderBanner'
import api from '../../../services/api'
import { ACCESS_TOKEN, COMMERCIAL_TOKEN } from '../../../enviroment'
import { useDispatch } from 'react-redux'
import { addNotification, retryLoginAndGetCurrentUser } from '../../../redux/actions'

const INITIAL_STATE = {
    user: {},
    companies: [],
    company_users: [],
    ownerToken: undefined,
    isDisableSelectUsers: true,
    isDisableButton: true,
    isOpenModal: false,
    loading: false,
    selected_company_id: 0,
    selected_user_id: 0,
}

const useCommercialProfile = () => {
    const [loading, setLoading] = useState(false)
    const [state, setState] = useState(INITIAL_STATE)

    const user = useSelector(SELECTOR_USER)

    const dispatch = useDispatch()

    const fetchCompanies = async () => {
        return await api.companies.find({ query: { $limit: 9999, $client: { myCompanies: 'true' } } })
            .then(res => res.data)
            .catch(() => [])
    }

    const fetchUsers = async (companyId) => {
        return await api.users.find({ query: { company_id: companyId, $limit: 9999 } })
            .then(res => res.data)
            .catch(() => [])
    }
    
    const initState = async () => {
        if (user) {
            setState(prev => ({...prev, user, ownerToken: localStorage.getItem(COMMERCIAL_TOKEN)}))
        }
    }

    const changeProfile = async () => {
        if (state.ownerToken) {
            localStorage.setItem(ACCESS_TOKEN, state.ownerToken)
            localStorage.removeItem(COMMERCIAL_TOKEN)
            dispatch(retryLoginAndGetCurrentUser())
        } else {
            if (loading) return
            setLoading(true)
            const companies = await fetchCompanies()
            setState(prev => ({...prev, isOpenModal: true, companies}))
            setLoading(false)
        }
    }

    const handleCloseModal = () => {
        setState({...INITIAL_STATE, user})
    }

    const handleSelectCompany = async (companyId) => {
        const users = await fetchUsers(companyId)
        setState(prev => ({
            ...prev,
            company_users: users,
            selected_company_id: companyId,
            isDisableSelectUsers: false
        }))
    }

    const handleSelectUser = (userId) => {
        setState(prev => ({...prev, selected_user_id: userId, isDisableButton: false}))
    }

    const handleAuthProfile = async () => {
        setState(prev => ({...prev, loading: true}))
        const currentToken = localStorage.getItem(ACCESS_TOKEN)
        
        await api.authentication
            .create({
                strategy: 'commercial',
                accessToken: currentToken,
                profile_id: state.selected_user_id,
            })
            .then(({accessToken, user}) => {
                localStorage.setItem(ACCESS_TOKEN, accessToken)
                localStorage.setItem(COMMERCIAL_TOKEN, currentToken)

                setState(() => ({
                    ...INITIAL_STATE,
                    user,
                    ownerToken: currentToken,
                }))
                dispatch(addNotification('Perfil cambiado correctamente.', 'success'));
                dispatch(retryLoginAndGetCurrentUser());
            })
            .catch((error) => {
                dispatch(addNotification(error.message, 'error'));
            });
        setState(prev => ({...prev, loading: false}))
    }

    useEffect(() => {
        initState()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user])

    if ((user && user.is_commercial === 'true') || state?.ownerToken) {
        return (
            <CommercialContext.Provider
                value={{
                    state,
                    loading,
                    changeProfile,
                    handleSelectCompany,
                    handleSelectUser,
                    handleCloseModal,
                    handleAuthProfile
                }}
            >
                <HeaderBanner />
            </CommercialContext.Provider>
        )

    }

    return <></>

}

export default useCommercialProfile