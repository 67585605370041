import { createActions, createReducer } from 'reduxsauce'
import { setValue, reset } from '../utilities'

const INITIAL_STATE = {
	discountId: 0,
	discount: null,
}

const { Types, Creators } = createActions({
	setDiscountId: ['discountId'],
	setDiscount: ['discount'],
	setReset: null
})

export const discounts = createReducer(INITIAL_STATE, {
	[Types.SET_DISCOUNT_ID]: setValue('discountId'),
	[Types.SET_DISCOUNT]: setValue('discount'),
	[Types.SET_RESET]: reset(INITIAL_STATE)
})

const reducer = {
	Types,
	Creators
}

export default reducer;