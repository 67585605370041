import {createActions, createReducer} from 'reduxsauce';
import {addList, delList, reset, setList, setValue} from '../utilities';

const INITIAL_STATE = {
  bankSelected: 0,
  banks: {},
};

const {Types, Creators} = createActions({
  setBankSelected: ['fulfillmentSelected'],
  setBanks: ['banks'],
  addBanks: ['banks'],
  delBanks: ['banks'],
  setReset: null,
});

// noinspection JSUnresolvedVariable
export const banks = createReducer(INITIAL_STATE, {
  [Types.SET_BANK_SELECTED]: setValue('bankSelected'),
  [Types.SET_BANKS]: setList('banks', 'bankCode'),
  [Types.ADD_BANKS]: addList('banks', 'bankCode'),
  [Types.DEL_BANKS]: delList('banks', 'bankCode'),
  [Types.SET_RESET]: reset(INITIAL_STATE),
});

export default {
  Types,
  Creators,
};
