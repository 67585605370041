import {createActions, createReducer} from 'reduxsauce';
import {reset, setValue} from '../utilities';

const INITIAL_STATE = {
  units: {},
};

const {Types, Creators} = createActions({
  setConfigsUnits: ['units'],
  setResetConfigs: null,
  setReset: null,
});

// noinspection JSUnresolvedVariable
export const configs = createReducer(INITIAL_STATE, {
  [Types.SET_CONFIGS_UNITS]: setValue('units'),
  [Types.SET_RESET_CONFIGS]: reset(INITIAL_STATE),
  [Types.SET_RESET]: reset(INITIAL_STATE),
});

export default {
  Types,
  Creators,
};
