import eWallet from '../reducers/eWallet';
import api from '../../services/api';

export function getTotalEWallet() {
  return async (dispatch) => {
    const {total, total_dms, total_wallet} = await api.vCarterasEdades.find({
      query: {$client: {total: 'true'}},
    });
    dispatch(eWallet.Creators.setBalance(total_dms));
    dispatch(eWallet.Creators.setBalance2(total_wallet));
    dispatch(eWallet.Creators.setBalance3(total));
  };
}

export function getEWallet() {
  return async (dispatch, getState) => {
    const {data, ...pagination} = await api.vCarterasEdades.find({
      query: {
        $limit: 25,
        $skip: 0,
        $sort: {Fecha: -1},
        Saldo: {$gt: 0},
        Sw: 1,
        $client: {wallet: 'true'},
      },
    });

    dispatch(eWallet.Creators.setEWallet(data));
    dispatch(eWallet.Creators.setEWalletPagination(pagination));
  };
}

export function getCustomPayments() {
  return async (dispatch) => {
    const {data, ...pagination} = await api.customPayments.find({
      query: {
        status: 'paid',
        $limit: 50,
      },
    });
    dispatch(eWallet.Creators.setCustomPayments(data));
    dispatch(eWallet.Creators.setCustomPaymentsPagination(pagination));
  };
}
