import {createActions, createReducer} from 'reduxsauce';
import {addList, delList, reset, setList, setValue} from '../utilities';

const INITIAL_STATE = {
  creditCardSelected: 0,
  creditCardSelectedDues: 1,
  creditCards: {},
};

const {Types, Creators} = createActions({
  setCreditCardSelected: ['creditCardSelected'],
  setCreditCards: ['creditCards'],
  addCreditCards: ['creditCards'],
  delCreditCards: ['creditCards'],
  setCreditCardSelectedDues: ['creditCardSelectedDues'],
  setReset: null,
});

// noinspection JSUnresolvedVariable
export const creditCards = createReducer(INITIAL_STATE, {
  [Types.SET_CREDIT_CARD_SELECTED]: setValue('creditCardSelected'),
  [Types.SET_CREDIT_CARDS]: setList('creditCards'),
  [Types.ADD_CREDIT_CARDS]: addList('creditCards'),
  [Types.DEL_CREDIT_CARDS]: delList('creditCards'),
  [Types.SET_CREDIT_CARD_SELECTED_DUES]: setValue('creditCardSelectedDues'),
  [Types.SET_RESET]: reset(INITIAL_STATE),
});

export default {
  Types,
  Creators,
};
