import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';

import clsx from 'clsx';

const useStylesField = makeStyles((theme) => ({
  search: (props) => ({
    position: 'relative',
    padding: 0,
    borderRadius: 8,
    // border: !props.disableOutline
    //   ? `1px solid ${theme.palette.primary.main}`
    //   : 'none',
    boxShadow: '1px 1px 8px rgb(174 174 174 / 39%)',
    maxHeight: 44,
    backgroundColor: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.common.white,
    },
    width: '100%',
    [theme.breakpoints.up('md')]: {
      maxWidth: 500,
    },
  }),
  searchIcon: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    top: -1,
    right: -1,
    bottom: -1,
    padding: theme.spacing(0, 1.5),
    backgroundColor: 'transparent',
    // backgroundColor: theme.palette.primary.main,
    border: 'none',
    // pointerEvents: 'none',
    cursor: 'pointer',
    '& svg': {
      color: '#0a598d',
    }
  },
  inputRoot: {
    width: '100% !important',
    color: 'inherit',
    fontWeight: 300,
  },
  inputInput: {
    padding: theme.spacing(1.5, 8, 1.5, 1.5),
    width: '100% !important',
    border: 'none',
    transition: theme.transitions.create('width'),
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));

const SearchField = React.forwardRef((props, ref) => {
  const {className, handleSearch, ...inputProps} = props;
  const classes = useStylesField(props);

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  return (
    <div className={clsx(classes.search, className)}>
      <InputBase
        fullWidth
        ref={ref}
        placeholder="Busca tus productos"
        onKeyPress={handleKeyPress}
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput,
        }}
        inputProps={{'aria-label': 'search'}}
        {...inputProps}
      />
      <button
        className={classes.searchIcon}
        onClick={() => handleKeyPress({key: 'Enter'})}
      >
        <SearchIcon />
      </button>
    </div>
  );
});

SearchField.propTypes = {
  white: PropTypes.bool,
  disableOutline: PropTypes.bool,
  handleSearch: PropTypes.func,
};

export default SearchField;
