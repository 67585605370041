import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { ThemeProvider } from '@material-ui/core/styles';
import { Provider } from 'react-redux';
import store from './redux';
import theme from './modules/theme';
import GA4React from 'ga-4-react';
import ReactPixel from 'react-facebook-pixel';

import 'react-phone-input-2/lib/material.css';

const advancedMatching = {}; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: false, // enable logs
};
ReactPixel.init('276995027134377', advancedMatching, options);
const ga4react = new GA4React('G-5EJ257SNKR', {}, [], 5000);

(async () => {
  await ga4react.initialize().catch((e) => console.log(e));

  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store()} onUpdate={() => window.scrollTo(0, 0)}>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </Provider>
    </React.StrictMode>,
    document.getElementById('root'),
  );

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://bit.ly/CRA-PWA
  serviceWorker.unregister();
})();
