import dayjs from 'dayjs';
import 'dayjs/locale/es';
import qs from 'qs';

dayjs.locale('es');

export const formatDate = (date) => dayjs(date).format('DD/MM/YYYY ');

// algolia

export const createURL = (state) => `?${qs.stringify(state)}`;

export const searchStateToUrl = (location, searchState) =>
  searchState ? `${location.pathname}${createURL(searchState)}` : '';

export const urlToSearchState = ({search}) => qs.parse(search.slice(1));

export function debounce(func, wait, immediate) {
  let timeout;
  return function () {
    let context = this,
      args = arguments;
    let later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    let callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}
