import { Box, DialogContent, MenuItem, Select, Typography } from '@material-ui/core'
import React from 'react'
import { useContext } from 'react'
import AppButton from '../../AppButton'
import AppDialog from '../../AppDialog'
import { CommercialContext } from '../context/commercial.context'

const ModalChangeProfile = () => {
    
    const { state, handleSelectCompany, handleSelectUser, handleCloseModal, handleAuthProfile } = useContext(CommercialContext)

    return (
        <AppDialog
            open={state.isOpenModal}
            maxWidth={500}
            handleClose={handleCloseModal}
        >
            <Box px={3} py={5}>
                <DialogContent>
                    <Typography color="secondary" align="center" variant="h6">
                        Cambiar perfil
                    </Typography>
                    <Box mt={3} mb={2}>
                        <Box mb={2}>
                            <Typography variant='subtitle2' component="h4">Seleccione una compañía</Typography>
                        </Box>
                        <Select
                            fullWidth
                            onChange={(event) => handleSelectCompany(event.target.value)}
                            value={state.selected_company_id}
                        >
                            {state.companies.map((it) => (
                                <MenuItem value={it.id} key={`profile-company-${it.id}`}>{it.name}</MenuItem>
                            ))}
                        </Select>
                    </Box>
                    <Box mt={3} mb={2}>
                        <Box mb={2}>
                            <Typography variant='subtitle2' component="h4">Seleccione un usuario</Typography>
                        </Box>
                        <Select
                            disabled={state.isDisableSelectUsers}
                            fullWidth
                            onChange={(event) => handleSelectUser(event.target.value)}
                            value={state.selected_user_id}
                        >
                            {state.company_users.map((it) => (
                                <MenuItem key={`profile-user-company-${it.id}`} value={it.id}>{it.first_name} {it.last_name}</MenuItem>
                            ))}
                        </Select>
                    </Box>
                    <Box mt={3} mb={2} display="flex" justifyContent="center">
                        <AppButton
                            disabled={state.isDisableButton}
                            round
                            onClick={handleAuthProfile}
                            loading={state.loading}
                            disableElevation
                            variant="contained"
                            color="secondary"
                        >
                            Aceptar
                        </AppButton>
                    </Box>
                </DialogContent>
            </Box>
        </AppDialog>
    )
}

export default ModalChangeProfile