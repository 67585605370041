import {red} from '@material-ui/core/colors';
import {createTheme} from '@material-ui/core/styles';
// import comfortabold from '../assets/fonts/comfortaa-bold-webfont.woff2';
// import comfortalight from '../assets/fonts/comfortaa-light-webfont.woff2';
// import comfortaregular from '../assets/fonts/comfortaa-regular-webfont.woff2';
// import comfortarounded from '../assets/fonts/somatic-rounded-webfont.woff2';

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: '#1374EE',
    },
    secondary: {
      main: '#E75A29',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#fff',
    },
    text: {
      primary: 'rgba(95, 95, 95, 1)',
      secondary: 'rgba(10, 89, 141, 1)',
      hint: '#9F9A9A',
    },
  },
  typography: {
    fontFamily: "comfortaaregular",
    subtitle2: {
      fontWeight: 600,
    },
    h5: {
      fontSize: '1rem',
      fontWeight: 500,
      lineHeight: 1.2,
    },
    body2: {
      fontWeight: 700,
    },
    overline: {
      fontWeight: 300,
    },
    button: {
      textTransform: 'capitalize',
      fontWeight: 600,
    },
  },
  // components: {
  //   MuiCssBaseline: {
  //     styleOverrides: `
  //       @font-face {
  //         font-family: 'comforta';
  //         font-style: normal;
  //         font-display: swap;
  //         font-weight: 400;
  //         src: local('comforta'), local('comforta-Regular'), url(${comfortaregular}) format('woff2');
  //       }
  //     `,
  //   },
  // },
});

export default theme;
