export const SELECTOR_ADDRESS_MAIN = (state) =>
  Object.values(state.addresses.addresses).find((it) => it.main === 'true');

export const SELECTOR_ADDRESSES = (state) =>
  Object.values(state.addresses.addresses);

export const SELECTOR_OTHERS_ADDRESSES = (state) =>
  Object.values(state.addresses.othersAddresses).filter(
    (address) =>
      !SELECTOR_ADDRESSES(state)
        .map((it) => it.integration_codigo_direccion)
        .includes(address.id),
  );
