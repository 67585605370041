import React from 'react';
import PropTypes from 'prop-types';
import ShoppingCart from '../ShoppingCart';
import {useDispatch, useSelector} from 'react-redux';
import {setLoginDialogOpen} from '../../redux/actions';
import LoginDialog from '../LoginDialog';
import Notifications from '../Notifications';

ShopifyLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.elementType]),
};

function ShopifyLayout(props) {
  const dispatch = useDispatch();

  const openLoginDialog = useSelector((state) => state.auth.loginDialogOpen);

  function handleCloseLoginDialog() {
    dispatch(setLoginDialogOpen(false));
  }

  return (
    <>
      {props.children}
      <LoginDialog open={openLoginDialog} handleClose={handleCloseLoginDialog} />
      <Notifications />
      <ShoppingCart />
    </>
  );
}

export default ShopifyLayout;
