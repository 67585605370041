import creditCardsReducer from '../reducers/creditCards';
import api from '../../services/api';

export function setCreditCardSelected(id) {
  return async (dispatch) => {
    dispatch(creditCardsReducer.Creators.setCreditCardSelected(id));
  };
}

export function getMyCreditCards() {
  return async (dispatch) => {
    const {data} = await api.creditCards.find();
    dispatch(creditCardsReducer.Creators.setCreditCards(data));
  };
}

export function setCreditCardSelectedDues(dues) {
  return async (dispatch) => {
    dispatch(creditCardsReducer.Creators.setCreditCardSelectedDues(dues));
  };
}
