/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import lodash from 'lodash';
import numeral from 'numeral';
import makeStyles from '@material-ui/core/styles/makeStyles';
import PropTypes from 'prop-types';
import { getPicture } from '../../../modules/imageHandler';
import { useHistory } from 'react-router-dom';
import { addToShoppingCart, setProductSelected } from '../../../redux/actions';
import AppButton from '../../AppButton';
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import { useGA4React } from 'ga-4-react';
import ReactPixel from 'react-facebook-pixel';
import { setShowModalSubscribeStockProduct } from '../../../redux/actions/uiConfig';
import { Skeleton } from '@material-ui/lab';

const useStylesItemProduct = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    flexGrow: 1,
    cursor: 'pointer',
    // width: ({maxWidth}) => maxWidth || 'auto',
    boxShadow: '1px 1px 5px #ebebebe0',
    backgroundColor: 'white',
    marginTop: '20px',
    padding: '20px',
    borderRadius: 6,
    '@media (max-width: 600px)': {
      padding: 8,
    },
  },
  imageContent: ({ small }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: small ? 240 : 340,
  }),
  image: {
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
    marginBottom: 15,
    objectFit: 'contain',
    width: '100%',
  },
  name: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    maxHeight: 42,
    height: 42,
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    color: '#464646',
    fontWeight: 'bold',
    textAlign: 'left',
    fontSize: 14,
    marginBottom: 0,
    '@media (max-width: 600px)': {
      fontSize: 14,
      maxHeight: 37,
      height: 40,
      lineHeight: '1.3',
    },
  },
  price: {
    textAlign: 'left',
    color: theme.palette.secondary.main,
    fontSize: 18,
    fontWeight: 'bold',
    '@media (max-width: 600px)': {
      fontSize: 15,
    },
  },
  opacityBackgroundWrapper: {
    position: 'absolute',
    top: 10,
    right: 30,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: 90,
    height: 90,
    borderRadius: '100%',
    backgroundColor: '#1374eea8',
  },
  tagSale: {
    fontSize: 14,
    color: 'white',
    position: 'absolute',
    padding: '4px 8px',
    top: 2,
    right: 2,
    zIndex: 88888,
    background: '#ef1719',
    borderBottomLeftRadius: 10,
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  addedProductButton: {
    borderRadius: '6px',
    width: '100%',
    marginTop: '10px',
    fontWeight: 'bold',
    fontSize: 14,
    '@media (max-width: 600px)': {
      lineHeight: '1.2',
    },
  },
}));

ItemProduct.propTypes = {
  small: PropTypes.bool,
  data: PropTypes.any.isRequired,
};

export default function ItemProduct({ small, data, width }) {
  const user = useSelector((state) => state.auth.user);

  const classes = useStylesItemProduct({ small });
  const dispatch = useDispatch();
  const history = useHistory();
  const image = getPicture(lodash.sortBy(data.media, 'priority').reverse()[0]?.path, 600, 600);

  const ga = useGA4React();

  const handleAddedShoppingCart = (e) => {
    e.preventDefault();
    e.stopPropagation();
    !!ga &&
      ga.gtag('event', 'add_to_cart', {
        currency: 'COP',
        value: getPrice(data.discount_price_whit_tax || data.price_with_tax, data.price_list?.product_price_list),
        items: [
          {
            item_id: data.id,
            item_name: data.name,
            quantity: 1,
            price: getPrice(data.discount_price_whit_tax || data.price_with_tax, data.price_list?.product_price_list),
          },
        ],
      });
    ReactPixel.track('AddToCart', {
      content_ids: [data.id.toString()],
      content_name: data.name,
      content_type: 'product',
      contents: [{ id: data.id.toString(), quantity: 1 }],
      currency: 'COP',
      value: getPrice(data.discount_price_whit_tax || data.price_with_tax, data.price_list?.product_price_list),
    });
    dispatch(addToShoppingCart({ productId: data.id, quantity: 1 }));
  };

  function getPrice(discount = true) {
    if (data.price_list) {
      // noinspection JSUnresolvedVariable
      const priceListID = user?.company?.price_list_id;
      // noinspection JSUnresolvedVariable
      const priceList = Array.isArray(data.price_list?.product_price_list)
        ? data.price_list?.product_price_list
        : data.price_list?.product_price_list?.price_list_id
          ? [data.price_list?.product_price_list]
          : Object.values(data.price_list?.product_price_list || {});
      const priceData = priceList?.find((it) => it.price_list_id === priceListID);

      return priceData?.price_with_tax || discount
        ? data.discount_price_whit_tax || data.price_with_tax
        : data.price_with_tax;
    }
    // noinspection JSUnresolvedVariable
    const priceListID = user?.company?.price_list_id;
    // noinspection JSUnresolvedVariable
    const priceList = data.product_list;
    const priceData = priceList?.find((it) => it.price_list_id === priceListID);
    return (
      priceData?.price_with_tax ||
      (discount ? data.discount_price_whit_tax || data.price_with_tax : data.price_with_tax)
    );
  }

  function onClick() {
    const { id, slug } = data;
    // const rawSection = getProductData(location.pathname)?.params?.section;
    // const section = rawSection ? `${rawSection}/` : '';
    history.push(`${slug}`);
    dispatch(setProductSelected(id));
  }

  return (
    <>
      {data.length <= 0 ? <Skeleton variant="rounded" width={"100%"} height={"auto"} /> :
        <div style={{ position: 'relative', width: width || 'auto', zIndex: 10 }}>
          <div className={classes.root} onClick={onClick}>
            {!!data.discount_price_whit_tax && data.quantity > 0 && (
              <div className={classes.tagSale}>oferta &nbsp;-{data.discount_percentage}%</div>
            )}
            <div className={`${classes.imageContent} box-image`} style={{ position: 'relative' }}>
              {data?.oversize === 'true' && (
                <span style={{ background: '#1374EE', position: 'absolute', bottom: 8, left: 0, color: 'white', padding: '0px 10px', borderRadius: 6 }}>Oversize</span>
              )}
              <img
                className={classes.image}
                src={image}
                alt={image}
                style={data.quantity <= 0 ? { opacity: '0.3' } : {}}
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = 'https://estrategia-web.s3.amazonaws.com/products/notfound.png';
                }}
              />
              {data.quantity <= 0 && (
                <div className={classes.opacityBackgroundWrapper}>
                  <span style={{ color: 'white', fontSize: '14px' }}>AGOTADO</span>
                </div>
              )}
            </div>
            <h3 className={classes.name}>{data.name}</h3>
            <h4 className={classes.price}>
              {numeral(getPrice()).format('$0,0')}
              {!!data.discount_price_whit_tax && (
                <span
                  style={{
                    textDecoration: 'line-through',
                    color: 'rgb(170,170,170)',
                    paddingLeft: 10,
                  }}
                >
                  {numeral(getPrice(false)).format('$0,0')}
                </span>
              )}
            </h4>
            {data.quantity > 0 ? (
              <AppButton
                color="primary"
                round
                disableElevation
                variant="contained"
                onClick={handleAddedShoppingCart}
                className={classes.addedProductButton}
              >
                <ShoppingCartOutlinedIcon style={{ paddingRight: 5 }} />
                AGREGAR AL CARRITO
              </AppButton>
            ) : (
              <AppButton
                color="secondary"
                round
                disableElevation
                variant="contained"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  dispatch(setShowModalSubscribeStockProduct(data.id));
                }}
                className={classes.addedProductButton}
              >
                NOTIFICAR DISPONIBILIDAD
              </AppButton>
            )}
            {/* <div className={classes.price}>
     {numeral(getPrice()).format('$0,0[.][00]')}
   </div> */}
          </div>
          {/* <div
   style={{
     position: 'absolute',
     display: 'flex',
     justifyContent: 'center',
     alignItems: 'center',
     top: 5,
     right: 5,
     width: 30,
     height: 30,
     maxWidth: 30,
     maxHeight: 30,
     padding: 5,
     borderRadius: 500,
     backgroundColor: '#f6f6f6',
     cursor: 'pointer',
   }}
   onClick={() => {
     const {id, slug} = data;
     const rawSection = getProductData(location.pathname)?.params?.section;
     const section = rawSection ? `${rawSection}/` : '';
     const url = `/${section}${slug}${location.search || ''}`;
     const word = '/p-';
     const newWord = '/quick-view-';
     const n = url.lastIndexOf(word);
     
     history.push(url.slice(0, n) + url.slice(n).replace(word, newWord));
     dispatch(setProductSelected(id));
   }}
   >
   <img
   style={{
     width: 20,
     height: 20,
     maxWidth: 20,
     maxHeight: 20,
     }}
     src="https://estrategia-web.s3.us-east-1.amazonaws.com/static/icons/view.svg"
     alt="icon-view"
     />
   </div> */}
        </div>
      }

    </>
  );
}
