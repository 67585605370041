import {createActions, createReducer} from 'reduxsauce';
import {addList, delList, reset, setList, setValue} from '../utilities';

const INITIAL_STATE = {
  categoriesSelected: 0,
  categories: {},
  categoriesTree: [],
};

const {Types, Creators} = createActions({
  setCategoriesSelected: ['categoriesSelected'],
  setCategories: ['categories'],
  addCategories: ['categories'],
  delCategories: ['categories'],
  setResetCategories: null,
  setCategoriesTree: ['categoriesTree'],
  setReset: null,
});

// noinspection JSUnresolvedVariable
export const categories = createReducer(INITIAL_STATE, {
  [Types.SET_CATEGORIES_SELECTED]: setValue('categoriesSelected'),
  [Types.SET_CATEGORIES]: setList('categories'),
  [Types.ADD_CATEGORIES]: addList('categories'),
  [Types.DEL_CATEGORIES]: delList('categories'),
  [Types.SET_RESET_CATEGORIES]: reset(INITIAL_STATE),
  [Types.SET_CATEGORIES_TREE]: setValue('categoriesTree'),
  [Types.SET_RESET]: reset(INITIAL_STATE),
});

export default {
  Types,
  Creators,
};
