import {createActions, createReducer} from 'reduxsauce';
import {addList, reset, setList, setValue} from '../utilities';

const INITIAL_STATE = {
  productPriceList: {},
  productPriceListTotals: {total: 0, limit: 0, skip: 0},
}

const {Types, Creators} = createActions({
  setProductPriceList: ['productPriceList'],
  addProductPriceList: ['productPriceList'],
  setProductPriceListTotals: ['productPriceListTotals'],
  setReset: null,
});

export const productPriceList = createReducer(INITIAL_STATE, {
  [Types.SET_PRODUCT_PRICE_LIST]: setList('productPriceList'),
  [Types.ADD_PRODUCT_PRICE_LIST]: addList('productPriceList'),
  [Types.SET_PRODUCT_PRICE_LIST_TOTALS]: setValue('productPriceListTotals'),
  [Types.SET_RESET]: reset(INITIAL_STATE),
});

export default {
  Types,
  Creators,
};