import React from 'react'

export const CommercialContext = React.createContext({
    state: {
        user: {},
        companies: [],
        company_users: [],
        ownerToken: undefined,
        isDisableSelectUsers: true,
        isDisableButton: true,
        isOpenModal: false,
        loading: false,
        selected_company_id: 0,
        selected_user_id: 0,
    },
    loading: false,
    changeProfile: () => undefined,
    handleSelectCompany: () => undefined,
    handleSelectUser: () => undefined,
    handleCloseModal: () => undefined,
    handleAuthProfile: () => undefined,
})