import {createActions, createReducer} from 'reduxsauce';
import {reset, setValue} from '../utilities';

const INITIAL_STATE = {
  query: '',
  page: 1,
  searchFilters: {},
};

const {Types, Creators} = createActions({
  setSearchQuery: ['query'],
  setSearchPage: ['page'],
  setSearchFilters: ['searchFilters'],
  setResetSearch: null,
  setReset: null,
});

// noinspection JSUnresolvedVariable
export const search = createReducer(INITIAL_STATE, {
  [Types.SET_SEARCH_QUERY]: setValue('query'),
  [Types.SET_SEARCH_PAGE]: setValue('page'),
  [Types.SET_SEARCH_FILTERS]: setValue('searchFilters'),
  [Types.SET_RESET_SEARCH]: reset(INITIAL_STATE),
  [Types.SET_RESET]: reset(INITIAL_STATE),
});

export default {
  Types,
  Creators,
};
