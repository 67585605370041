export const SELECTOR_PRODUCTS = (state) =>
  Object.values(state.products.products);

export const SELECTOR_PRODUCT_SELECTED = (state) =>
  state.products.products[state.products.select];

export const SELECTOR_PRODUCT_SELECTED_ID = (state) => state.products.select;

export const SELECTOR_PRODUCT_REVIEWS = (state) => Object.values(state.products.reviews)

export const SELECTOR_FAVORITE_PRODUCTS = (state) => Object.values(state.products.favorites)

export const SELECTOR_FAVORITE_PRODUCTS_TOTALS = (state) => state.products.favoritesTotals