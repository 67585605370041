import React from 'react';
import Button from '@material-ui/core/Button';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const CustomButton = withStyles((theme) => ({
  root: (props) => ({
    borderRadius: props.round ? 25 : 4,
  }),
  outlined: {
    borderColor: '#797979',
  },
}))(
  React.forwardRef(({round, ...props}, ref) => <Button ref={ref} {...props} />),
);

const useStyles = makeStyles((theme) => ({
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const AppButton = ({children, loading, ...props}) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <CustomButton disableElevation {...props}>
        {children}
      </CustomButton>
      {loading && (
        <CircularProgress size={24} className={classes.buttonProgress} />
      )}
    </div>
  );
};

export default AppButton;
