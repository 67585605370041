/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, Suspense, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import CssBaseline from '@material-ui/core/CssBaseline';
import Guard from './components/Guard';
import { retryLoginAndGetCurrentUser } from './redux/actions';
import { lazyImport } from './util/lazyImport';
import { useGA4React } from 'ga-4-react';
import ReactPixel from 'react-facebook-pixel';
import BaseLayout from './components/layouts/base';
import ShopifyLayout from './components/layouts/shopify';
import './styles/fonts.css'

const Home = lazyImport(import('./views/Home'));
const Search = lazyImport(import('./views/Search/Search'));
const Contact = lazyImport(import('./views/Contact'));
const Account = lazyImport(import('./views/Account'));
const Login = lazyImport(import('./views/Login'));
const Checkout = lazyImport(import('./views/Checkout2/index'));
const Cms = lazyImport(import('./views/Cms'));
const Product = lazyImport(import('./views/Product'));
const RapidOrder = lazyImport(import('./views/RapidOrder'));
const Thanks = lazyImport(import('./views/Thanks'));

const routes = [
  { path: ['/', '/quick-view-:id-:name'], component: Home, layout: BaseLayout },
  {
    path: ['/b', '/b/quick-view-:p_id-:p_name', '/c-:c_id-:c_name', '/c-:c_id-:c_name/quick-view-:p_id-:p_name'],
    component: Search,
    layout: BaseLayout,
  },
  { path: ['/p-:p_id-:p_name/:s?'], component: Product, layout: BaseLayout },
  { path: ['/info/contacto'], component: Contact, layout: BaseLayout },
  {
    path: ['/mi-cuenta', '/mi-cuenta/:section', '/mi-cuenta/:section/:sectionId'],
    component: Account,
    layout: BaseLayout,
  },
  { path: ['/login'], component: Login, layout: BaseLayout },
  { path: ['/pedido-rapido'], component: RapidOrder, layout: BaseLayout },
  { path: ['/thanks'], component: Thanks, layout: BaseLayout },
  {
    path: ['/checkout/info', '/checkout/delivery', '/checkout/pay'],
    component: Checkout,
    layout: ShopifyLayout,
  },
  { path: ['/info/:name'], component: Cms, layout: BaseLayout },
];

export function ScrollToTop() {
  const location = useLocation();

  const ga = useGA4React();

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!!ga) {
      ga.pageview(location.pathname + (location.search || ''));
      ReactPixel.pageView()
    }
  }, [location.pathname, !!ga]);

  return null;
}

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(retryLoginAndGetCurrentUser());
    return () => { };
  }, []);

  return (
    <Fragment>
      <Router>
        <ScrollToTop />
        <CssBaseline />
        <Suspense fallback={'...'}>
          <Guard>
            <Switch>
              {routes.map(({ path, layout: Layout, component: Component }, i) => (
                <Route
                  key={i}
                  exact
                  path={path}
                  render={() => (
                    <Layout>
                      <Component />
                    </Layout>
                  )}
                />
              ))}
            </Switch>
          </Guard>
        </Suspense>
      </Router>
    </Fragment>
  );
}

export default App;
