import {createActions, createReducer} from 'reduxsauce';
import {addList, delList, reset, setList, setValue} from '../utilities';

const INITIAL_STATE = {
  orderSelected: 0,
  orders: {},
  ordersTotals: {total: 0, limit: 0, skip: 0},
  othersOrderSelected: 0,
  othersOrders: {},
  othersOrdersTotals: {total: 0, limit: 0, skip: 0},
};

const {Types, Creators} = createActions({
  setOrderSelected: ['orderSelected'],
  setOrders: ['orders'],
  addOrders: ['orders'],
  delOrders: ['orders'],
  setOrdersTotals: ['ordersTotals'],
  setOthersOrderSelected: ['othersOrderSelected'],
  setOthersOrders: ['othersOrders'],
  addOthersOrders: ['othersOrders'],
  delOthersOrders: ['othersOrders'],
  setOthersOrdersTotals: ['othersOrdersTotals'],
  setResetOrders: null,
  setReset: null,
});

// noinspection JSUnresolvedVariable
export const orders = createReducer(INITIAL_STATE, {
  [Types.SET_ORDER_SELECTED]: setValue('orderSelected'),
  [Types.SET_ORDERS]: setList('orders'),
  [Types.ADD_ORDERS]: addList('orders'),
  [Types.DEL_ORDERS]: delList('orders'),
  [Types.SET_ORDERS_TOTALS]: setValue('ordersTotals'),
  [Types.SET_OTHERS_ORDER_SELECTED]: setValue('othersOrderSelected'),
  [Types.SET_OTHERS_ORDERS]: setList('othersOrders', 'numero'),
  [Types.ADD_OTHERS_ORDERS]: addList('othersOrders', 'numero'),
  [Types.DEL_OTHERS_ORDERS]: delList('othersOrders', 'numero'),
  [Types.SET_OTHERS_ORDERS_TOTALS]: setValue('othersOrdersTotals'),
  [Types.SET_RESET_ORDERS]: reset(INITIAL_STATE),
  [Types.SET_RESET]: reset(INITIAL_STATE),
});

export default {
  Types,
  Creators,
};
