import React from 'react';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import {amber, blue, green} from '@material-ui/core/colors';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import WarningIcon from '@material-ui/icons/Warning';
import {connect} from 'react-redux';
import {makeStyles} from '@material-ui/core/styles';
import {removeNotification} from '../redux/actions';
import clsx from 'clsx';

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const useStyles = makeStyles((theme) => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: blue[600],
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const MySnackbarContentWrapper = ({className, message, onClose, variant, ...other}) => {
  const classes = useStyles();
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      className={clsx(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={clsx(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={[
        <IconButton key="close" aria-label="close" color="inherit" onClick={onClose}>
          <CloseIcon className={classes.icon} />
        </IconButton>,
      ]}
      {...other}
    />
  );
};

const Notifications = ({notifications, removeNotification, className, message, onClose, variant, ...other}) => {
  return (
    <React.Fragment>
      {notifications.map((notification) => (
        <Snackbar
          style={{zIndex: 9999}}
          key={notification.id}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={true}
          autoHideDuration={notification.type === 'warning' ? null : 3000}
          onClose={() => removeNotification(notification.id)}
        >
          <MySnackbarContentWrapper
            onClose={() => removeNotification(notification.id)}
            variant={notification.type}
            message={notification.text}
          />
        </Snackbar>
      ))}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    notifications: state.notifications.messages,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    removeNotification: (id) => {
      dispatch(removeNotification(id));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
