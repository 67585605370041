import React from 'react'
import { Box, Grid, makeStyles, Typography } from "@material-ui/core"
import { Modal } from "antd"
import { useDispatch } from 'react-redux';
import { addNotification } from '../redux/actions';
import api from '../services/api'
import { Form, Formik } from 'formik';
import { useSelector } from 'react-redux';
import { SELECTOR_USER } from '../redux/selectors/auth';
import * as yup from 'yup'
import Text from './fields/Text';
import PhoneInput from 'react-phone-input-2';
import AppButton from './AppButton';

const useStyles = makeStyles((theme) => ({
  label: (props) => ({
    fontSize: '.9rem',
    fontWeight: props.labelBold ? 700 : 300,
    marginBottom: theme.spacing(1),
  }),
}));


const validationRegisterForm = yup.object().shape({
  full_name: yup.string().required('Campo requerido.'),
  email: yup.string().email('Ingrese un email válido.').required('Campo requerido.'),
  phone: yup.string().required('Campo requerido.'),
  quantity: yup.number().integer().required('Campo requerido.').typeError('Ingrese una cantidad correcta.')
});

const ModalSubscribeUserProduct = ({ product_id, open, onCancel }) => {
  const user = useSelector(SELECTOR_USER)
  const classes = useStyles()

  const dispatch = useDispatch();

  const onSubmit = async (values, { resetForm }) => {
    console.log({ values });
    const {
      full_name,
      email,
      phone,
      quantity
    } = values

    const payload = {
      product_id: Number(product_id),
      full_name,
      email,
      phone,
      quantity: Number(quantity),
    }

    await api.productsNotifications.create({
      product_id: Number(product_id),
      ...payload,
    })
      .then(() => {
        dispatch(addNotification('Te has suscrito.', 'success'))
      })
      .catch(() => {
        dispatch(addNotification('Te has suscrito.', 'success'))
      })
      .finally(() => {
        resetForm()
        onCancel()
      })
  }

  return (
    <Modal
      destroyOnClose
      footer={false}
      centered
      open={open}
      onCancel={onCancel}
      width={450}>
      <Box padding={2}>
        <Typography
          variant='h4'
          style={{
            fontSize: 20,
            fontWeight: 600,
            marginBottom: 10,
          }}
        >
          ¡No te lo pierdas cuando este artículo esté disponible!
        </Typography>
        <Typography variant="body1">
          Ingresa tus datos para recibir una notificación cuando este producto vuelva a estar disponible.
        </Typography>

        <Box marginTop={2}>
          <Formik
            enableReinitialize
            validationSchema={validationRegisterForm}
            onSubmit={onSubmit}
            initialValues={{
              full_name: Object.keys(user).length ? `${user.first_name} ${user.last_name}` : '',
              email: Object.keys(user).length ? user.email : '',
              phone: Object.keys(user).length ? user.phone : '',
              phone_country_code: Object.keys(user).length ? user.phone_country_code : '',
              quantity: null,
            }}
          >
            {({
              values,
              isSubmitting,
              errors,
              handleChange,
              handleSubmit,
              setFieldValue,
            }) => (
              <Form onKeyPress={(event) => event.key === 'Enter' && handleSubmit()}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Text
                      xt
                      name="full_name"
                      label="Nombre completo"
                      value={values.full_name}
                      onChange={handleChange}
                      error={!!errors.full_name}
                      helperText={errors.full_name}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Text
                      xt
                      name="email"
                      label="Correo electrónico"
                      value={values.email}
                      onChange={handleChange}
                      error={!!errors.email}
                      helperText={errors.email}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography color="textPrimary" className={classes.label}>
                      Celular
                    </Typography>
                    <PhoneInput
                      enableSearch
                      masks={{ co: '(...) ... ....' }}
                      country={'co'}
                      specialLabel={''}
                      value={values.phone_country_code + values.phone}
                      onChange={(phone) => {
                        setFieldValue('phone', phone);
                      }}
                      inputStyle={{
                        width: '100%',
                        border: '1px solid #e2e2e1',
                        backgroundColor: '#fcfcfb',
                        height: 58,
                      }}
                      containerStyle={{
                        width: '100%',
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Text
                      xt
                      name="quantity"
                      label="Cantidad requerida"
                      value={values.quantity}
                      onChange={handleChange}
                      error={!!errors.quantity}
                      helperText={errors.quantity}
                    />
                  </Grid>
                </Grid>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  mt={3}
                >
                  <AppButton
                    type="submit"
                    round
                    disableElevation
                    variant="contained"
                    color="primary"
                    size="lg"
                    onClick={handleSubmit}
                    disabled={isSubmitting}
                  >
                    Notificarme
                  </AppButton>
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
      </Box>
    </Modal>
  )
}

export default ModalSubscribeUserProduct