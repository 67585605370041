import feathersClient from './feathers-connection';
import ServiceClass from './service.class';
import { ACCESS_TOKEN, STORAGE } from '../enviroment';

const feathersService = {
  login: (email, password) =>
    feathersClient.authenticate({
      strategy: 'local',
      email: email,
      password: password,
    }),
  retryLogin: () =>
    feathersClient.authenticate({
      strategy: 'jwt',
      accessToken: STORAGE.getItem(ACCESS_TOKEN),
    }),
  logout: () => feathersClient.logout(),
  current: () => feathersClient.service('current-user').find({}),
  purchaseOrders: new ServiceClass('purchase-orders'),
  products: new ServiceClass('products'),
  banners: new ServiceClass('banners'),
  users: new ServiceClass('users'),
  userProductsViews: new ServiceClass('user-products-views'),
  brands: new ServiceClass('brands'),
  companies: new ServiceClass('companies'),
  design: new ServiceClass('design'),
  customPayments: new ServiceClass('custom-payments'),
  orders: new ServiceClass('orders'),
  ordersDetails: new ServiceClass('orders-details'),
  cms: new ServiceClass('cms'),
  searchCreditCompany: new ServiceClass('search-credit-company'),
  shoppingCart: new ServiceClass('shopping-cart'),
  shoppingCartDetails: new ServiceClass('shopping-cart-details'),
  categories: new ServiceClass('categories'),
  authentication: new ServiceClass('authentication'),
  creditCards: new ServiceClass('credit-cards'),
  addresses: new ServiceClass('addresses'),
  states: new ServiceClass('locations-states'),
  cities: new ServiceClass('locations-cities'),
  processOrderPayments: new ServiceClass('process-order-payments'),
  fulfillmentCost: new ServiceClass('fulfillment-cost'),
  configurations: new ServiceClass('configurations'),
  companiesFiles: new ServiceClass('companies-files'),
  companiesFilesList: new ServiceClass('companies-files-list'),
  contact: new ServiceClass('contact'),
  vCarterasEdades: new ServiceClass('v-cartera-edades'),
  documentosPed: new ServiceClass('documentos-ped'),
  documentosPedHistoria: new ServiceClass('documentos-ped-historia'),
  integrationsTerceros: new ServiceClass('integrations-terceros'),
  companiesBudgets: new ServiceClass('companies-budgets'),
  companiesBudgetsUsers: new ServiceClass('companies-budgets-users'),
  productPriceList: new ServiceClass('product-price-list'),
  companiesProductInternalCode: new ServiceClass('companies-product-internal-code'),
  //en creacion, esperando backend....
  productReviews: new ServiceClass('reviews'),
  discounts: new ServiceClass('discounts'),
  favorites: new ServiceClass('favorites'),
  productsNotifications: new ServiceClass('products-notifications'),
  paymentConf: new ServiceClass('paymethod-config'),
};

export default feathersService;



