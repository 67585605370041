import React from 'react';
import {Box, Grid, makeStyles, Typography, useMediaQuery} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  cardSpec: {
    background: 'white',
    width: '100%',
    height: 'auto',
    padding: '10px 20px',
  },
  specItem: {
    display: 'flex',
    padding: theme.spacing(2),
    justifyContent: 'space-between',
    fontSize: 16,
    flexWrap: 'nowrap',
    '&.hovered': {
      background: '#f8f8f8',
    },
    '& .specTextTile, & .specText': {
      display: 'inline-block',
    },
    '& .specTextTitle': {
      fontSize: 14,
      lineHeight: 1.8,
      fontWeight: 'bold',
    },
    '& .specText': {
      fontWeight: 400,
      marginLeft: theme.spacing(2),
    },
  },
}));

const ProductSpecifications = ({data}) => {
  const isMobil = useMediaQuery('(max-width:768px)');
  const classes = useStyles({isMobil});

  return (
    <Grid container spacing={2} p={5} justifyContent='center'>
      {/* specifications */}
      {!data?.characteristics.length && (
         <Box mt={2}>
            <Typography variant="body2" >
              Sin especificaciones
            </Typography>
         </Box>
      )}
      {(data?.characteristics && !!data?.characteristics.length) && (
        <Grid item sm={12} md={8} className={classes.cardSpec}>
          {data?.characteristics.map((x, i) => (
            <Grid key={`product_spec_${i}`} container className={`${classes.specItem} ${i % 2 === 0 ? 'hovered' : ''}`}>
              <Grid item sm={6}>
                <Typography variant="h6" className="specTextTitle">
                  {x.name}
                </Typography>
              </Grid>
              <Grid item sm={6}>
                <Typography variant="body2" className="specText">
                  {x.characteristic_value}
                </Typography>
              </Grid>
            </Grid>
          ))}
        </Grid>
      )}
    </Grid>
  );
};

export default ProductSpecifications;
