import { isLocalhost } from './util/isLocalhost';

export const TIMEOUT = 60000;

export const HOST_API = isLocalhost
  ? 'localhost:3030'
  : 'api.estrategias-ltda.com';
export const PROTOCOL_WS = isLocalhost ? 'ws' : 'wss';
export const PROTOCOL_HTTP = isLocalhost ? 'http' : 'https';

export const S3_HOST = 'https://estrategia-web.s3.amazonaws.com';

export const ACCESS_TOKEN = 'feathers-jwt';
export const COMMERCIAL_TOKEN = 'owner-jwt';
export const STORAGE = window.localStorage;

export const SHOPPING_CART_TOKEN = 'sc-token';
export const SHOPPING_CART_ID = 'sc-id';

export const PRODUCT_VIEWS_TOKEN = 'pv-token';
