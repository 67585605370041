import React from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Logo from '../assets/images/logofooter.svg';
import LogoCamara from '../assets/images/logoCamaraComercioFooter.jpg';
import LogoSic from '../assets/images/logoDeSic.png';
import InstagramIcon from '@material-ui/icons/Instagram';
import FacebookIcon from '@material-ui/icons/Facebook';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    // borderTop: '4px solid #005A8B',
    color: 'white',
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(5),
  },
  footerDelFooter: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    color: '#89BAF7',
    fontWeight: 600,
    textAlign: 'center',
    fontSize: 16,
    borderTop: '1px solid #0A588A'
  },
  title: {
    fontWeight: 'bold',
    fontSize: '1rem',
    color: 'white',
    letterSpacing: 0,
  },
  subtitle: {
    fontWeight: 400,
    fontSize: '1rem',
    // color: '#464646',
    lineHeight: '1.2',
    margin: 0,
  },
  info: {
    paddingTop: 25,
  },
  smallIcon: {
    minWidth: 40,
  },
  containerFooter: {
    alignItems: 'flex-end',
    '& > div': {
      paddingRight: 30,
    },
  },
  gridSpacing: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(4),
    },
  },
  rowIcons: {
    alignSelf: 'center',
    '& h4': {
      marginBottom: 10
    },
    '& svg': {
      fontSize: 40,
      color: 'white',
      marginRight: 10,
    },
  },
  contentLogos: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    width: "100%",
    backgroundColor: "#fff",
    padding: "4px"
  },
  logoFooter: {
    width: "100%",
    maxWidth: "300px",
    height: "100%",
    maxHeight: "65px",
    cursor: "pointer",
  },
  logoSicFooter: {
    width: "100%",
    maxWidth: "400px",
    height: "100%",
    maxHeight: "40px",
    cursor: "pointer",
  },
}));

const Footer = () => {
  const classes = useStyles();

  const match = useRouteMatch('/contacto');
  const history = useHistory();

  if (match) return <footer />;

  return (
    <>
      <div className={classes.root}>
        <Container>
          <Grid container>
            <Grid xs={12} md={12} item>
              <Grid container className={classes.containerFooter}>
                <Grid xs={12} md={3} item>
                  <img src={Logo} onClick={() => history.push('/')} className={classes.logo} alt="eltda" />
                  <List component="nav" aria-label="main mailbox folders">
                    <ListItem disableGutters>
                      <ListItemText
                        disableTypography
                        className={classes.subtitle}
                        primary="Vía 40 No. 69 - 58 Bodega D8 Barranquilla, Colombia"
                      />
                    </ListItem>
                    <ListItem disableGutters style={{ display: 'block' }}>
                      <ListItemText
                        className={classes.subtitle}
                        disableTypography
                        primary="+(57) (5) 3858077"
                      />
                      <ListItemText
                        className={classes.subtitle}
                        disableTypography
                        primary="+(57) 323 4793073"
                      />
                      <ListItemText
                        className={classes.subtitle}
                        disableTypography
                        primary="mercadeo@estrategias-ltda.com"
                      />
                    </ListItem>
                  </List>
                </Grid>
                <Grid xs={12} md={3} item>
                  <Typography
                    className={classes.title}
                    variant="h6"
                    component="h4"
                  >
                    ACERCA DE ESTRATEGIAS LTDA
                  </Typography>
                  <List component="nav" aria-label="main mailbox folders">
                    <ListItem
                      disableGutters
                      dense
                      button
                      onClick={() => history.push(encodeURI('/info/Conócenos'))}
                    >
                      <ListItemText
                        className={classes.subtitle}
                        disableTypography
                        primary="Conócenos"
                      />
                    </ListItem>
                    <ListItem
                      disableGutters
                      dense
                      button
                      onClick={() => history.push(encodeURI('/info/Clientes'))}
                    >
                      <ListItemText
                        className={classes.subtitle}
                        disableTypography
                        primary="Clientes"
                      />
                    </ListItem>
                    <ListItem
                      disableGutters
                      dense
                      button
                      onClick={() => history.push(encodeURI('/info/Contacto'))}
                    >
                      <ListItemText
                        className={classes.subtitle}
                        disableTypography
                        primary="Contacto"
                      />
                    </ListItem>
                    {/* <ListItem
                      disableGutters
                      dense
                      button
                      onClick={() =>
                        (window.location.href =
                          'https://outsourcing.estrategias-ltda.com/login.php')
                      }
                    >
                      <ListItemText
                        className={classes.subtitle}
                        disableTypography
                        primary="e-portal"
                      />
                    </ListItem> */}
                  </List>
                </Grid>
                <Grid xs={12} md={4} item>
                  <Typography className={classes.title} variant="h6" component="h4">
                    INFORMACIÓN
                  </Typography>
                  <List component="nav" aria-label="main mailbox folders">
                    <ListItem
                      disableGutters
                      dense
                      button
                      onClick={() =>
                        history.push(encodeURI('/info/Términos y Condiciónes'))
                      }
                    >
                      <ListItemText
                        className={classes.subtitle}
                        disableTypography
                        primary="Términos y Condiciones"
                      />
                    </ListItem>
                    <ListItem
                      disableGutters
                      dense
                      button
                      onClick={() =>
                        history.push(
                          encodeURI('/info/Política de protección de datos'),
                        )
                      }
                    >
                      <ListItemText
                        className={classes.subtitle}
                        disableTypography
                        primary="Política de protección de datos"
                      />
                    </ListItem>
                    <ListItem
                      disableGutters
                      dense
                      button
                      onClick={() => {
                        const url = `https://estrategia-web.s3.us-east-1.amazonaws.com/static/Politica%20de%20devoluci%C3%B3n%20en%20venta%20y%20garantia/Politica%20de%20devoluci%C3%B3n%20en%20venta%20y%20garantia.pdf`;
                        const a = document.createElement('a');
                        a.style.display = 'none';
                        a.href = url;
                        a.target = '_blank';
                        document.body.appendChild(a);
                        a.click();
                        window.URL.revokeObjectURL(url);
                        a.remove();
                      }}
                    >
                      <ListItemText
                        className={classes.subtitle}
                        disableTypography
                        primary="Politica de devolución en venta y garantía"
                      />
                    </ListItem>
                  </List>
                </Grid>
                <Grid xs={12} md={2} item className={classes.rowIcons}>
                  <Typography
                    className={classes.title}
                    variant="h6"
                    component="h4"
                  >
                    SÍGUENOS EN:
                  </Typography>
                  <a href='https://instagram.com/estrategiassas?igshid=MmU2YjMzNjRlOQ==' target='_blank' rel="noopener noreferrer">
                    <InstagramIcon />
                  </a>
                  <a href='https://www.facebook.com/Estrategiassas' target='_blank' rel="noopener noreferrer">
                    <FacebookIcon />
                  </a>
                </Grid>
              </Grid>
            </Grid>
            {/* <Grid xs={12} md={3} item>
              <List
                className={classes.info}
                component="nav"
                aria-label="main mailbox folders"
              >
                <ListItem>
                  <ListItemIcon className={classes.smallIcon}>
                    <PhoneIcon color="inherit" />
                  </ListItemIcon>
                  <ListItemText
                    className={classes.subtitle}
                    disableTypography
                    primary="+(57) (5) 3858077"
                  />
                </ListItem>
              </List>
            </Grid>
            <Grid xs={12} md={2} item>
              <List
                className={classes.info}
                component="nav"
                aria-label="main mailbox folders"
              >
                <ListItem>
                  <ListItemIcon className={classes.smallIcon}>
                    <EmailIcon color="inherit" />
                  </ListItemIcon>
                  <ListItemText
                    className={classes.subtitle}
                    disableTypography
                    primary="mercadeo@estrategias-ltda.com"
                  />
                </ListItem>
              </List>
            </Grid> */}
          </Grid>
        </Container>
      </div>
      <Grid xs={12} item className={classes.contentLogos} style={{ height: "100px", width: "100%" }}>
        <a href="https://www.ccce.org.co/" target="_blank">
          <img src={LogoCamara} className={classes.logoFooter} alt="cámaraDeComercio" />
        </a>
        <a href="https://www.sic.gov.co/" target="_blank">
          <img src={LogoSic} className={classes.logoSicFooter} alt="sic" />
        </a>
      </Grid>
      <div className={classes.footerDelFooter}>
        <Container>
          <Grid container>
            <Grid xs={12} item>
              © 2021 Estrategias LTDA - Desarrollado y diseñado por e-me.co.
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
};

export default Footer;
