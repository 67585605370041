import {encode} from 'base-64';
//Esto es para fotos
export const S3Photos = {
  bucket: 'estrategia-web',
  base: 'https://dwism5cwvtbn6.cloudfront.net/',
};

export const getFullPicture = (path) => {
  const json = JSON.stringify({
    bucket: S3Photos.bucket,
    key: path,
  });
  const base64 = encode(json);
  const url = `${S3Photos.base}${base64}`;
  if (!path) return ' ';
  return url;
};

export const getPicture = (path, w, h) => {
  const json = JSON.stringify({
    bucket: S3Photos.bucket,
    key: path,
    edits: {
      resize: {
        width: w,
      },
    },
  });
  const base64 = encode(json);
  const url = `${S3Photos.base}${base64}`;
  if (!path) return ' ';
  return url;
};

export const getCroppedPicture = (path, {width, height}) => {
  const json = JSON.stringify({
    bucket: S3Photos.bucket,
    key: path,
    edits: {
      resize: {
        width: width || 400,
        height: height || 400,
        fit: 'contain',
        background: {
          r: 255,
          g: 255,
          b: 255,
          alpha: 1,
        },
      },
    },
  });

  const base64 = encode(json);
  const url = `${S3Photos.base}${base64}`;
  if (!path) return ' ';
  return url;
};

export const getEstablishmentBanner = (url = '') => {
  const path = url.replace(S3Photos.base, '').replace(S3Photos.oldBase, '');
  const key = path[0] === '/' ? path.replace('/', '') : path;
  const json = JSON.stringify({
    bucket: S3Photos.bucket,
    key,
    edits: {
      resize: {
        width: 320,
        height: 150,
        fit: 'cover',
      },
    },
  });
  const base64 = encode(json);
  return `${S3Photos.base}${base64}`;
};
