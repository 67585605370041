import banksReducer from '../reducers/banks';
import api from '../../services/api';

export function getBanks() {
  return async (dispatch, getState) => {
    const urlBase = 'https://secure.payco.co/restpagos/pse/bancos.json';
    const {value} = await api.configurations.get(10, {});
    const {data: banks} = await fetch(`${urlBase}?public_key=${value}`).then(
      (response) => {
        return response.json();
      },
    );
    dispatch(banksReducer.Creators.setBanks(banks));
  };
}

export function setBankSelected(id) {
  return (dispatch) => {
    dispatch(banksReducer.Creators.setBankSelected(id));
  };
}
