import fulfillmentReducer from '../reducers/fulfillments';
import {SELECTOR_ADDRESS_MAIN} from '../selectors/addresses';
import {SELECTOR_SHOPPING_CART_ID} from '../selectors/shoppingCart';
import api from '../../services/api';

export function getFulfillments() {
  return async (dispatch, getState) => {
    const state = getState();
    const address = SELECTOR_ADDRESS_MAIN(state);
    const shoppingCartId = SELECTOR_SHOPPING_CART_ID(state);
    const data = await api.fulfillmentCost.find({
      query: {
        address_id: address.id,
        shopping_cart_id: shoppingCartId,
      },
    });
    if (data) {
      const fulfillments = Object.values(data)
        .filter((it) => !!it)
        .map((it) => (it.service_code ? it : {...it, service_code: `no-${it.fulfillment_company_id}`}));
      dispatch(fulfillmentReducer.Creators.setFulfillments(fulfillments));
    }
  };
}

export function setResetFulfillment() {
  return (dispatch) => {
    dispatch(fulfillmentReducer.Creators.setResetFulfillments());
  };
}

export function setFulfillmentSelected(id) {
  return (dispatch) => {
    dispatch(fulfillmentReducer.Creators.setFulfillmentSelected(id));
  };
}
